Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "promocodes";
exports.labelBodyText = "promocodes Body";
exports.btnExampleTitle = "CLICK ME";
exports.getPromocodesEndPoint = "promo_codes/promo_codes?city_id=";
exports.getPromocodesAPIMethod = "GET";

exports.getAllDiscountValueEndPoint = "bx_block_promo_codes/discount_values"
exports.getAllDiscountValueAPIMethod = "GET"
exports.getAllDiscountTypeEndPoint = "bx_block_promo_codes/discount_types"
exports.getAllDiscountTypeAPIMethod = "GET"
exports.getAllCategoriesEndPoint= "bx_block_categories/sub_categories"
exports.getAllCategoriesAPIMethod = "GET"
exports.getAllPromocodesEndPoint = "bx_block_promo_codes/promo_codes"
exports.getAllPromocodesAPIMethod = "GET"
exports.createPromocodeEndPoint = "bx_block_promo_codes/promo_codes"
exports.createPromocodeAPIMethod = "POST"
exports.updatePromocodeEndPoint = "bx_block_promo_codes/promo_codes"
exports.updatePromocodeAPIMethod = "PATCH"

exports.getFAQsEndpoint = "bx_block_advertisement/faqs";
exports.getCampaignsEndpoint = "bx_block_advertisement/advertisements";
exports.getMonthlyBudgetEndpoint = "bx_block_advertisement/advertisements/monthly_budgets";

exports.getAdminAdvertisingListEndpoint = "bx_block_advertisement/advertisements/advertisement_super_admin";
exports.updateAdvertisingEndpoint = "bx_block_advertisement/advertisements";
exports.downloadAdminAdvertisingReport = "bx_block_advertisement/advertisements/download_report";
// Customizable Area End
