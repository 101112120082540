import React from "react";

// Customizable Area Start
import {
  Box,
  Grid,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import { ArrowUpward, SaveAlt } from "@material-ui/icons";
import clsx from "clsx";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  Cell,
  Pie,
  PieChart,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomTabs from "../../../components/src/CustomTabs";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomUserItem from "../../../components/src/CustomUserItem.web";
import KitchenFeedbackOverviewRow, {
  FeedbackOverview,
} from "../../../components/src/KitchenFeedbackOverviewRow.web";

import CustomAnalyticsAdminSalesRevenueChart from "../../../components/src/analytics/CustomAnalyticsAdminSalesRevenueChart.web";
import CustomAnalyticsOperationMetric, {
  Operation,
} from "../../../components/src/analytics/CustomAnalyticsOperationMetric.web";
import CustomAnalyticsOperationStatistics, {
  OperationStatisticsProps,
} from "../../../components/src/analytics/CustomAnalyticsOperationStatistics.web";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import CustomTable from "../../../components/src/CustomTable";
import {
  customLocaleMoment,
  formatNumber,
} from "../../../components/src/Utils.web";
import {
  AnalyticsSalesCompare,
  AnalyticsSalesMenuItem,
  AnalyticsSalesPerHour,
  AnalyticsSalesRevenue,
  DailyAverage,
  OperationOrder,
  PopularFoodProps,
  TABS,
} from "./AnalyticsController.web";

const styles = (theme: Theme) =>
  createStyles({
    content: {
      border: "1px solid #EDEDED",
      padding: 24,
      borderRadius: 10,
    },

    head: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
    },

    contentInfo: {
      maxWidth: 646,
    },

    contentDescription: {
      marginTop: 8,

      fontWeight: "500" as any,
      lineHeight: "20.72px",
    },

    block: {
      boxShadow: "0px 0px 4px 0px #00000040",
      borderRadius: 10,
      padding: "24px 40px",
      boxSizing: "border-box",
    },

    feedbackOverviewTableContainer: {
      marginTop: 28,
    },

    tabContent: {
      marginTop: 16,
    },

    dailyAverageHead: {
      display: "flex",
      justifyContent: "space-between",
    },

    dailyAverageTitle: {
      fontSize: "7.01px !important",
      fontWeight: "500 !important" as any,
      lineHeight: "8.49px !important",
    },

    dailyAverageTime: {
      marginTop: 8,
      color: "#2E2E30 !important",
      fontSize: "14.02px !important",
      lineHeight: "16.97px !important",
    },

    dailyAveragePlusTime: {
      fontSize: "7.01px !important",
      fontWeight: "500 !important" as any,
      lineHeight: "8.49px !important",
      color: "#FF955A !important",
    },

    dailyAverageHeadRight: {
      display: "flex",
      alignItems: "center",
      gap: 20,
    },

    dailyAverageChartLegend: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-evenly",
      columnGap: 40,
      rowGap: 12,
    },

    dailyAverageChartLegendItem: {
      display: "flex",
      alignItems: "center",
      gap: 5,
      fontSize: "7.01px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "8.49px !important",
      color: "#767676 !important",

      "&::before": {
        width: 5,
        height: 5,
        display: "inline-block",
        borderRadius: "50%",
        content: '" "',
        boxSizing: "border-box",
      },

      "&.news": {
        "&::before": {
          backgroundColor: "#344BFD",
        },
      },

      "&.games": {
        "&::before": {
          backgroundColor: "#F4A79D",
        },
      },

      "&.social": {
        "&::before": {
          backgroundColor: "#F68D2B",
        },
      },
    },

    timeSelect: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      maxWidth: "65%",
    },

    timeSelectText: {
      fontSize: "12px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "14.52px !important",
    },

    revenueTitle: {
      fontSize: "20px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "24.2px !important",
      textAlign: "center",
      flex: "1 0 auto",
    },

    menuItemId: {
      width: 160,
      fontFamily: "Inter !important",
    },

    compensationReason: {
      maxWidth: 140,
      fontFamily: "Inter !important",
    },

    menuItem: {
      width: 132,
      fontFamily: "Inter !important",
    },

    percentage: {
      width: 139,
      fontFamily: "Inter !important",
    },

    percentageNumber: {
      display: "flex",
      alignItems: "center",
      gap: 5,
    },

    subtotal: {
      width: 139,
      fontFamily: "Inter !important",
    },

    orderId: {
      maxWidth: 100,
      fontFamily: "Inter !important",
    },

    itemDetails: {
      maxWidth: 120,
      fontFamily: "Inter !important",
    },

    amount: {
      maxWidth: 70,
      fontFamily: "Inter !important",
    },

    category: {
      maxWidth: 120,
      fontFamily: "Inter !important",
    },

    notes: {
      maxWidth: 100,
      fontFamily: "Inter !important",
    },

    createdAt: {
      maxWidth: 100,
      fontFamily: "Inter !important",
    },

    operationStatisticsContent: {
      marginTop: 26,
    },

    operationStatisticsTitle: {
      fontSize: "12px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "14.52px !important",
      textAlign: "center",
    },

    popularFood: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    popularFoodTitle: {
      fontSize: "12px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "14.52px !important",
      textAlign: "center",
    },

    popularFoodLegend: {
      marginTop: 32,
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      rowGap: 14,
      justifyContent: "space-between",
    },

    popularFoodLegendItem: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    popularFoodLegendItemDot: {
      width: 12,
      height: 12,
      borderRadius: "50%",
    },

    popularFoodLegendItemName: {
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      color: "#0F172A !important",
    },

    popularFoodLegendItemNumber: {
      marginLeft: 4,
      fontWeight: "700 !important" as any,
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      color: "#0F172A !important",
    },

    menuItemTable: {
      "& td": {
        padding: "12px !important",
      },
    },

    salesPerHour: {
      marginTop: 24,
    },

    salesPerHourTableContainer: {
      marginTop: 8,
    },

    salesPerHourTable: {
      "& td": {
        padding: "12px !important",
      },
    },

    revenueHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    switch: {
      display: "flex",
      alignItems: "center",
      gap: 8,
      marginTop: 8,
    },

    control: {
      textAlign: "right",
    },

    previousPeriodLegend: {
      marginTop: 32,
      width: "100%",
      display: "flex",
      flexWrap: "wrap",
      rowGap: 14,
    },

    previousPeriodLegendItem: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    previousPeriodLegendItemDot: {
      width: 12,
      height: 12,
      borderRadius: "50%",
    },

    previousPeriodLegendItemName: {
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      color: "#0F172A !important",
      fontWeight: "700 !important" as any,
    },
  });

interface SaleContentProps extends WithStyles, WithTranslation {
  analyticsSalesMenuItems: AnalyticsSalesMenuItem[];
  analyticsSalesRevenue: AnalyticsSalesRevenue[];
  analyticsSalesPerHours: AnalyticsSalesPerHour[];
  analyticsSalesCompareData: AnalyticsSalesCompare[];
  filterTime: string;
  timeOptions: { id: string; name: string; value: string }[];
  previousPeriod: boolean;

  changeFilterTime(value: string): void;
}

function SaleContentComponent(props: SaleContentProps) {
  const {
    classes,
    t,
    analyticsSalesRevenue,
    analyticsSalesMenuItems,
    analyticsSalesPerHours,
    filterTime,
    timeOptions,
    previousPeriod,
    analyticsSalesCompareData,

    changeFilterTime,
  } = props;

  const yTicks = [1000, 2000, 3000];

  return (
    <Box className={classes.reportMain}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
          {previousPeriod ? (
            <Box className={classes.block}>
              <Box style={{ textAlign: "right" }}>
                <CustomSelect
                  className={classes.filterSelect}
                  data-test-id="time-select"
                  placeholder="Time"
                  variant="outlined"
                  value={filterTime}
                  onChange={(e: any) => changeFilterTime(e.target.value)}
                >
                  {timeOptions.map((option) => (
                    <CustomMenuItem value={option.value} key={option.id}>
                      {t(option.name)}
                    </CustomMenuItem>
                  ))}
                </CustomSelect>
              </Box>

              <CustomTypography
                variant="subtitle1"
                style={{
                  fontSize: 24,
                }}
              >
                $3650
              </CustomTypography>

              <Box className={classes.previousPeriodLegend}>
                <Box
                  className={classes.previousPeriodLegendItem}
                  style={{ marginLeft: 18 }}
                >
                  <Box
                    className={classes.previousPeriodLegendItemDot}
                    style={{ backgroundColor: "#FF6666" }}
                  />
                  <CustomTypography
                    className={classes.previousPeriodLegendItemName}
                  >
                    {t("Current Period")}
                  </CustomTypography>
                </Box>

                <Box
                  className={classes.previousPeriodLegendItem}
                  style={{ marginLeft: 60 }}
                >
                  <Box
                    className={classes.previousPeriodLegendItemDot}
                    style={{ backgroundColor: "#059669" }}
                  />
                  <CustomTypography
                    className={classes.previousPeriodLegendItemName}
                  >
                    {t("Previous Period")}
                  </CustomTypography>
                </Box>
              </Box>

              <ResponsiveContainer
                style={{ marginTop: 20 }}
                width="100%"
                height={280}
              >
                <BarChart
                  width={500}
                  height={300}
                  data={analyticsSalesCompareData}
                  barCategoryGap="35%"
                >
                  <XAxis
                    dataKey="date"
                    tickLine={{ display: "none" }}
                    stroke="#B1B1B1"
                    strokeWidth="0.29px"
                    interval={0}
                    tickMargin={10}
                    fontFamily="Inter"
                    fontWeight={700}
                    fontSize={10}
                    color="#767676"
                  />

                  <YAxis
                    ticks={yTicks}
                    tickMargin={10}
                    fontFamily="Inter"
                    fontWeight={700}
                    fontSize={10}
                    color="#767676"
                    stroke="#B1B1B1"
                    strokeWidth={0}
                    tickLine={{ display: "none" }}
                    tickFormatter={(value) => {
                      return `${value}$`;
                    }}
                  />

                  {yTicks.map((tick) => (
                    <ReferenceLine
                      key={tick}
                      y={tick}
                      stroke="#B1B1B1"
                      strokeWidth="0.29px"
                    />
                  ))}

                  <Tooltip />

                  <Bar dataKey="current" fill="#FF6666" radius={[3, 3, 3, 3]} />

                  <Bar dataKey="period" fill="#059669" radius={[3, 3, 3, 3]} />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          ) : (
            <Box className={classes.block}>
              <Box className={classes.revenueHead}>
                <Box style={{ flex: 1 }} />

                <CustomTypography className={classes.revenueTitle}>
                  {t("Revenue Updates")}
                </CustomTypography>

                <Box style={{ flex: 1, textAlign: "right" }}>
                  <CustomSelect
                    className={classes.filterSelect}
                    data-test-id="time-select"
                    placeholder="Time"
                    variant="outlined"
                    value={filterTime}
                    onChange={(e: any) => changeFilterTime(e.target.value)}
                  >
                    {timeOptions.map((option) => (
                      <CustomMenuItem value={option.value} key={option.id}>
                        {t(option.name)}
                      </CustomMenuItem>
                    ))}
                  </CustomSelect>
                </Box>
              </Box>

              <Box marginTop={"94px"} maxWidth={500} marginX={"auto"}>
                <CustomAnalyticsAdminSalesRevenueChart
                  data={analyticsSalesRevenue}
                />
              </Box>
            </Box>
          )}

          <Box className={classes.salesPerHour}>
            <CustomTypography variant="h5" style={{ paddingLeft: 12 }}>
              Sales Per Hour
            </CustomTypography>

            <TableContainer className={classes.salesPerHourTableContainer}>
              <CustomTable className={classes.salesPerHourTable}>
                <TableBody>
                  {analyticsSalesPerHours.map((data, index) => (
                    <TableRow key={index} data-test-id={`table-row-${index}`}>
                      <TableCell className={classes.percentage}>
                        {customLocaleMoment(data.time).format("hh:mm")}
                      </TableCell>
                      <TableCell className={classes.subtotal}>
                        {data.value ? data.value : ""}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </CustomTable>
            </TableContainer>
          </Box>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
          <TableContainer className={classes.menuItemTableContainer}>
            <CustomTable className={classes.menuItemTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("ID")}</TableCell>
                  <TableCell>{t("Menu Item")}</TableCell>
                  <TableCell>{t("Percentage")}</TableCell>
                  <TableCell style={{ textAlign: "right" }}>
                    {t("Subtotal")}
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {analyticsSalesMenuItems.map((data) => (
                  <TableRow key={data.id} data-test-id={`table-row-${data.id}`}>
                    <TableCell className={classes.menuItemId}>
                      {data.id}
                    </TableCell>

                    <TableCell className={classes.menuItem}>
                      <Box>{data.menuItem}</Box>
                      <Box>{data.menuItem}</Box>
                    </TableCell>

                    <TableCell className={classes.percentage}>
                      <Box className={classes.percentageNumber}>
                        <ArrowUpward
                          fontSize="small"
                          style={{ fill: "#F87171" }}
                        />
                        <Box>{data.percentage}</Box>
                      </Box>
                    </TableCell>
                    <TableCell
                      className={classes.subtotal}
                      style={{ textAlign: "right" }}
                    >
                      {t("SAR {{number}}", { number: data.subtotal })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </CustomTable>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
}

const SaleContent = withStyles(styles)(withTranslation()(SaleContentComponent));

interface OperationContentProps extends WithStyles, WithTranslation {
  operationMetric: Operation[];
  operationOrders: OperationOrder[];
  operationStatistics: OperationStatisticsProps[];
  popularFood: PopularFoodProps[];
}

function OperationContentComponent(props: OperationContentProps) {
  const {
    classes,
    operationMetric,
    operationOrders,
    operationStatistics,
    popularFood,
    t,
    i18n,
  } = props;

  customLocaleMoment.locale(i18n.language);

  return (
    <Box className={classes.customerContent}>
      <Box className={classes.top}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
            <Box className={classes.block}>
              <CustomAnalyticsOperationMetric
                data-test-id="operation-analytics-operation-metric"
                operationMetric={operationMetric}
              />
            </Box>

            <TableContainer
              className={classes.operationOrderTableContainer}
              style={{ marginTop: 17 }}
            >
              <CustomTable className={classes.operationOrderTable}>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("Order ID")}</TableCell>
                    <TableCell>
                      <Box>{t("Compensation")}</Box>
                      <Box>{t("Reason")}</Box>
                    </TableCell>
                    <TableCell>{t("Item Details")}</TableCell>
                    <TableCell>{t("Amount (SAR)")}</TableCell>
                    <TableCell>{t("Category")}</TableCell>
                    <TableCell>{t("Notes")}</TableCell>
                    <TableCell>{t("Created At")}</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {operationOrders.map((data) => (
                    <TableRow
                      key={data.id}
                      data-test-id={`table-row-${data.id}`}
                    >
                      <TableCell className={classes.orderId}>
                        {data.orderId}
                      </TableCell>
                      <TableCell className={classes.compensationReason}>
                        <Box>{data.reason}</Box>
                        <Box>{data.compensation}</Box>
                      </TableCell>
                      <TableCell className={classes.itemDetails}>
                        {data.itemDetails}
                      </TableCell>
                      <TableCell className={classes.amount}>
                        {data.amount}
                      </TableCell>
                      <TableCell className={classes.category}>
                        {data.category}
                      </TableCell>
                      <TableCell className={classes.notes}>
                        {data.notes}
                      </TableCell>
                      <TableCell className={classes.createdAt}>
                        <Box>
                          {customLocaleMoment(data.createdAt).format(
                            "DD/MM/yyyy"
                          )}
                        </Box>
                        <Box>
                          {customLocaleMoment(data.createdAt).format("hh:mmA")}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </CustomTable>
            </TableContainer>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
            <Box className={classes.block} style={{ height: "auto" }}>
              <Box className={classes.operationStatistics}>
                <CustomTypography
                  className={classes.operationStatisticsTitle}
                  variant="subtitle1"
                >
                  Statistics
                </CustomTypography>

                <Box
                  className={classes.operationStatisticsContent}
                  style={{
                    paddingBottom: 37,
                  }}
                >
                  {operationStatistics.map((data, index) => (
                    <CustomAnalyticsOperationStatistics
                      key={index}
                      statistics={data.statistics}
                      theme={data.theme}
                    />
                  ))}
                </Box>
              </Box>
            </Box>

            <Box className={classes.block} style={{ marginTop: 15 }}>
              <Box className={classes.popularFood}>
                <CustomTypography
                  className={classes.popularFoodTitle}
                  variant="subtitle1"
                >
                  Popular Food
                </CustomTypography>

                <ResponsiveContainer
                  width={171}
                  height={171}
                  style={{
                    marginTop: 30,
                  }}
                >
                  <PieChart width={171} height={171} onMouseEnter={() => {}}>
                    <Pie
                      data={popularFood}
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      paddingAngle={0}
                      stroke="none"
                      dataKey="value"
                      width={171}
                      height={171}
                    >
                      {popularFood.map((data) => (
                        <Cell key={data.name} fill={data.color} />
                      ))}
                    </Pie>
                  </PieChart>
                </ResponsiveContainer>

                <Box className={classes.popularFoodLegend}>
                  {popularFood.map((data) => (
                    <Box
                      className={classes.popularFoodLegendItem}
                      key={data.name}
                    >
                      <Box
                        className={classes.popularFoodLegendItemDot}
                        style={{ backgroundColor: data.color }}
                      />
                      <CustomTypography
                        className={classes.popularFoodLegendItemName}
                      >
                        {t(data.name)}
                      </CustomTypography>
                      <CustomTypography
                        className={classes.popularFoodLegendItemNumber}
                      >
                        {formatNumber(data.value)}
                      </CustomTypography>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const OperationContent = withStyles(styles)(
  withTranslation()(OperationContentComponent)
);

interface CustomerContentProps extends WithStyles, WithTranslation {
  users: any[];
  feedbackOverviewData: FeedbackOverview[];
  dailyAverageData: DailyAverage[];
  operationMetric: Operation[];

  onSelectUserAction(id: string): void;
}

const CustomBar = (props: any) => {
  const { x, y, width, height, fill, radius } = props;

  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      radius={radius}
    />
  );
};

function CustomerContentComponent(props: CustomerContentProps) {
  const {
    classes,
    users,
    feedbackOverviewData,
    operationMetric,
    dailyAverageData,

    onSelectUserAction,
  } = props;

  return (
    <Box className={classes.customerContent}>
      <Box className={classes.top}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={8} md={8} lg={9} xl={9}>
            <Box className={classes.block}>
              <CustomAnalyticsOperationMetric
                data-test-id="customer-analytics-operation-metric"
                operationMetric={operationMetric}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={3} xl={3}>
            <Box className={classes.block}>
              <Box className={classes.dailyAverageHead}>
                <Box className={classes.dailyAverageHeadLeft}>
                  <CustomTypography
                    className={classes.dailyAverageTitle}
                    variant="subtitle1"
                  >
                    Daily Average
                  </CustomTypography>

                  <CustomTypography
                    className={classes.dailyAverageTime}
                    variant="subtitle1"
                  >
                    2h 20m
                  </CustomTypography>
                </Box>

                <Box className={classes.dailyAverageHeadRight}>
                  <CustomTypography
                    className={classes.dailyAveragePlusTime}
                    variant="subtitle1"
                  >
                    +30m
                  </CustomTypography>

                  <CustomTypography
                    className={classes.dailyAverageTitle}
                    variant="subtitle1"
                  >
                    this week
                  </CustomTypography>
                </Box>
              </Box>

              <Box>
                <ResponsiveContainer width="100%" height={340}>
                  <BarChart
                    width={500}
                    height={300}
                    data={dailyAverageData}
                    barCategoryGap="30%"
                  >
                    <XAxis
                      dataKey="date"
                      stroke="#B1B1B1"
                      strokeWidth={"0.29px"}
                      tick={{
                        fill: "#767676",
                        fontSize: 7,
                        fontFamily: "Inter",
                      }}
                      tickLine={{ display: "none" }}
                      tickMargin={10}
                    />

                    <YAxis tick={false} hide />

                    <Tooltip />

                    <Bar
                      data-test-id="social-bar"
                      dataKey="social"
                      stackId="a"
                      fill="#EE902C"
                      shape={(props: any) => {
                        const shouldRoundTop =
                          !props.payload.games && !props.payload.news;

                        return (
                          <CustomBar
                            {...props}
                            radius={
                              shouldRoundTop ? [10, 10, 0, 0] : [0, 0, 0, 0]
                            }
                          />
                        );
                      }}
                    />

                    <Bar
                      data-test-id="games-bar"
                      dataKey="games"
                      stackId="a"
                      fill="#F4A1AF"
                      shape={(props: any) => {
                        const shouldRoundTop = !props.payload.news;

                        return (
                          <CustomBar
                            {...props}
                            radius={
                              shouldRoundTop ? [10, 10, 0, 0] : [0, 0, 0, 0]
                            }
                          />
                        );
                      }}
                    />

                    <Bar
                      data-test-id="news-bar"
                      dataKey="news"
                      stackId="a"
                      fill="#344BFD"
                      shape={(props: any) => (
                        <CustomBar {...props} radius={[10, 10, 0, 0]} />
                      )}
                    />
                  </BarChart>
                </ResponsiveContainer>

                <Box className={classes.dailyAverageChartLegend}>
                  <CustomTypography
                    variant="subtitle1"
                    className={clsx(
                      classes.dailyAverageChartLegendItem,
                      "news"
                    )}
                  >
                    News 2h
                  </CustomTypography>

                  <CustomTypography
                    variant="subtitle1"
                    className={clsx(
                      classes.dailyAverageChartLegendItem,
                      "games"
                    )}
                  >
                    Games 20h
                  </CustomTypography>

                  <CustomTypography
                    variant="subtitle1"
                    className={clsx(
                      classes.dailyAverageChartLegendItem,
                      "social"
                    )}
                  >
                    Social Media 5h
                  </CustomTypography>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.bottom}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={8} xl={9}>
            <Box className={classes.block}>
              <CustomTypography variant="subtitle1">
                Feedback Overview
              </CustomTypography>

              <TableContainer
                className={classes.feedbackOverviewTableContainer}
              >
                <Table>
                  <TableBody>
                    {feedbackOverviewData.map((feedbackOverview) => (
                      <KitchenFeedbackOverviewRow
                        key={feedbackOverview.id}
                        feedbackOverview={feedbackOverview}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={4} xl={3}>
            <Box>
              {users.map((user) => (
                <CustomUserItem
                  data-test-id={`custom-user-item-${user.id}`}
                  key={user.id}
                  name={user.name}
                  userId={user.userId}
                  onSelectAction={(action: string) =>
                    onSelectUserAction(user.id)
                  }
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

const CustomerContent = withStyles(styles)(
  withTranslation()(CustomerContentComponent)
);
// Customizable Area End

import AnalyticsController, {
  Props,
  configJSON
} from "./AnalyticsController.web";

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box className={this.props.classes.container}>
        <CustomTabs
          variant="scrollable"
          scrollButtons="auto"
          value={this.state.tabActive}
          dir={this.getDir()}
        >
          {this.state.tabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                data-test-id={"tab-" + index}
                label={this.props.t(tab.name)}
                value={tab.value}
                dir={this.getDir()}
                onClick={() => this.setState({ tabActive: tab.value })}
              />
            );
          })}
        </CustomTabs>

        <Box className={this.props.classes.content} dir={this.getDir()}>
          <Box className={this.props.classes.head} dir={this.getDir()}>
            <Box className={this.props.classes.contentInfo}>
              <CustomTypography
                variant="h2"
                className={this.props.classes.heading}
              >
                {this.getReportTitle()}
              </CustomTypography>

              <CustomTypography
                className={this.props.classes.contentDescription}
              >
                {this.getReportDescription()}
              </CustomTypography>
            </Box>

            <Box className={this.props.classes.control}>
              <CustomButton
                variant="contained"
                color="primary"
                className={this.props.classes.downloadBtn}
                startIcon={<SaveAlt />}
                dir={this.getDir()}
              >
                {this.props.t("Download Report")}
              </CustomButton>

              {this.state.tabActive === TABS.SALES && (
                <Box className={this.props.classes.switch}>
                  <CustomTypography className={this.props.classes.switchLabel}>
                    {this.props.t("Compare with Previous Period")}
                  </CustomTypography>

                  <CustomSwitch
                    data-test-id="previous-period-switch"
                    checked={this.state.previousPeriod}
                    onChange={this.togglePreviousPeriod}
                  />
                </Box>
              )}
            </Box>
          </Box>

          <Box className={this.props.classes.tabContent}>
            {(() => {
              switch (this.state.tabActive) {
                case TABS.SALES:
                  return (
                    <SaleContent
                      data-test-id="sale-content"
                      analyticsSalesRevenue={this.state.analyticsSalesRevenue}
                      analyticsSalesMenuItems={
                        this.state.analyticsSalesMenuItems
                      }
                      analyticsSalesPerHours={this.state.analyticsSalesPerHours}
                      analyticsSalesCompareData={
                        this.state.analyticsSalesCompareData
                      }
                      filterTime={this.state.filterTime}
                      timeOptions={this.filterTimeList}
                      previousPeriod={this.state.previousPeriod}
                      changeFilterTime={this.changeFilterTime}
                    />
                  );

                case TABS.OPERATIONS:
                  return (
                    <OperationContent
                      data-test-id="operation-content"
                      operationMetric={this.state.operationMetric}
                      operationOrders={this.state.operationOrders}
                      popularFood={this.state.popularFood}
                      operationStatistics={[
                        this.state.operationStatisticsTotalOrder,
                        this.state.operationStatisticsTotalSales,
                        this.state.operationStatisticsTotalPending,
                      ]}
                    />
                  );

                case TABS.CUSTOMERS:
                  return (
                    <CustomerContent
                      data-test-id="customer-content"
                      users={this.state.users}
                      operationMetric={this.state.operationMetric}
                      feedbackOverviewData={this.state.feedbackOverviewData}
                      dailyAverageData={this.state.dailyAverageData}
                      onSelectUserAction={() => {}}
                    />
                  );

                default:
                  return <></>;
              }
            })()}
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

export const AnalyticsWithTranslation = withStyles(styles)(
  withTranslation()(Analytics)
);
// Customizable Area End
