// Customizable Area Start
import React from "react";

import {
  Box,
  IconButton,
  InputAdornment,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Portal,
} from "@material-ui/core";

import AdminPromocodesAdvertisingController, {
  Props,
} from "./AdminPromocodesAdvertisingController.web";

import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import clsx from "clsx";
import moment from "moment";

import { Close, Search, SaveAlt } from "@material-ui/icons";
import AdminPromocodesAdvertisingDetailFormDialog from "../../../components/src/AdminPromocodesAdvertisingDetailFormDialog.web";
import AdminPromocodesAdvertisingMenu from "../../../components/src/AdminPromocodesAdvertisingMenu.web";
import AdminPromocodesAdvertisingEngagement from "../../../components/src/AdminPromocodesAdvertisingEngagement.web";
import AdminPromocodesAdvertisingStatusTag from "../../../components/src/AdminPromocodesAdvertisingStatusTag.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomTable from "../../../components/src/CustomTable";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomInputField from "../../../components/src/CustomInputField.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    actions: {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",

      [theme.breakpoints.down("md")]: {
        flexDirection: "column-reverse",
        gap: 10,
      },
    },

    filter: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexWrap: "wrap",
    },

    filterSelect: {
      width: "100%",
    },

    restaurantFilterSelect: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 3,
        flex: 1,
      },
    },

    filterSelectTime: {
      width: 180,

      [theme.breakpoints.down("md")]: {
        order: 2,
        flex: 1,
      },
    },

    downloadButton: {
      marginLeft: "auto",

      "&.rtl": {
        marginLeft: 0,
        marginRight: "auto",
      },
    },

    table: {
      border: "1px solid #EDEDED",
      borderRadius: 8,
      padding: 16,
      marginTop: 38,
    },

    advertisingTable: {
      "& td": {
        padding: 16,
      },

      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },
    },

    requestId: {
      fontFamily: "Inter !important",
    },

    startDate: {
      fontFamily: "Inter !important",
    },

    endDate: {
      fontFamily: "Inter !important",
    },

    pagination: {
      marginTop: 24,
    },

    action: {
      display: "flex",
      gap: 5,
      justifyContent: "end",
    },
  });

export class AdminPromocodesAdvertising extends AdminPromocodesAdvertisingController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;

    const rtl = i18n.language === "ar";

    const dir = rtl ? "rtl" : "";

    const {
      advertisingList,
      timeFilter,
      restaurantFilter,
      requestIdSearch,
      loading,
      snackbar,
      pagination,
      adminAdvertisingDetailDialog,
    } = this.state;

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Box className={classes.actions}>
          <Box className={classes.filter}>
            <Box className={classes.filterSelectTime}>
              <CustomSelect
                dir={dir}
                className={classes.filterSelect}
                data-test-id="time-select"
                displayEmpty
                variant="outlined"
                value={timeFilter.value}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                  const selectedValue = e.target.value;
                  const selectedOption = this.timeOptions.find(
                    (option) => option.value === selectedValue
                  );

                  this.changeTimeFilter(selectedValue, selectedOption);
                }}
              >
                {this.timeOptions.map((option) => (
                  <CustomMenuItem
                    dir={dir}
                    value={option.value}
                    key={option.id}
                  >
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <Box className={classes.restaurantFilterSelect}>
              <CustomSelect
                dir={dir}
                className={classes.filterSelect}
                data-test-id="restaurant-select"
                variant="outlined"
                value={restaurantFilter}
                onChange={(e: any) =>
                  this.changeRestaurantFilter(e.target.value)
                }
                displayEmpty
                inputProps={{
                  renderValue: (selected: string) => {
                    if (!selected) {
                      return t("Restaurant");
                    }

                    const selectedOption = this.restaurantOptions.find(
                      (option: any) => option.value === selected
                    );

                    if (!selectedOption) {
                      return "";
                    }

                    return t(selectedOption.name);
                  },
                }}
              >
                {this.restaurantOptions.map((option) => (
                  <CustomMenuItem
                    dir={dir}
                    value={option.value}
                    key={option.id}
                  >
                    {t(option.name)}
                  </CustomMenuItem>
                ))}
              </CustomSelect>
            </Box>

            <CustomInputField
              dir={dir}
              data-test-id="search-request-id-input"
              className={classes.promotionIdSearch}
              inputRef={this.inputRef}
              variant="outlined"
              placeholder={t("Request ID")}
              InputProps={{
                endAdornment: (
                  <>
                    {requestIdSearch && (
                      <IconButton
                        data-test-id="clear-search-request-btn"
                        size="small"
                        aria-label="toggle password visibility"
                        onClick={this.clearRequestIdSearch}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <Search />
                    </InputAdornment>
                  </>
                ),
              }}
              onKeyDown={this.handleSearch}
            />
          </Box>

          <CustomButton
            className={clsx(classes.downloadButton, { ["rtl"]: rtl })}
            startIcon={<SaveAlt />}
            variant="contained"
            color="primary"
            data-test-id="download-advertising-report-btn"
            onClick={this.downloadReport}
          >
            {t("Download Report")}
          </CustomButton>
        </Box>

        <Box className={classes.table}>
          <TableContainer>
            <CustomTable className={classes.advertisingTable}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Request ID")}</TableCell>
                  <TableCell>{t("Restaurant Name")}</TableCell>
                  <TableCell>{t("Start")}</TableCell>
                  <TableCell>{t("End")}</TableCell>
                  <TableCell>{t("Engagement")}</TableCell>
                  <TableCell>{t("Revenue")}</TableCell>
                  <TableCell>{t("Request Status")}</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>

              <TableBody>
                {advertisingList.length ? (
                  advertisingList.map((data, index) => (
                    <TableRow key={index} data-test-id={`table-row-${index}`}>
                      <TableCell className={classes.requestId}>
                        {data.requestId}
                      </TableCell>

                      <TableCell className={classes.name}>
                        {data.restaurantName}
                      </TableCell>

                      <TableCell className={classes.startDate}>
                        {moment(data.startDate).format("yyyy-MM-DD")}
                      </TableCell>

                      <TableCell className={classes.endDate}>
                        {moment(data.endDate).format("yyyy-MM-DD")}
                      </TableCell>

                      <TableCell className={classes.endDate}>
                        <AdminPromocodesAdvertisingEngagement
                          engagement={data.engagement}
                        />
                      </TableCell>

                      <TableCell className={classes.endDate}>
                        {t("{{number}} SAR", { number: data.revenue })}
                      </TableCell>

                      <TableCell className={classes.status}>
                        <AdminPromocodesAdvertisingStatusTag
                          status={data.status}
                        />
                      </TableCell>
                      <TableCell>
                        <Box className={classes.action}>
                          <AdminPromocodesAdvertisingMenu
                            data-test-id="menu"
                            onSelectAction={(item) =>
                              this.handleMenuActions(item, data)
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7}>
                      <Box style={{ textAlign: "center" }}>{t("No data")}</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              data-test-id="pagination"
              fullWidth
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              showOffset
            />
          </Box>
        </Box>

        <AdminPromocodesAdvertisingDetailFormDialog
          data-test-id="admin-advertising-detail-dialog"
          dialog={adminAdvertisingDetailDialog}
          onClose={this.closeAdminAdvertisingDetailDialog}
          editAdminAdvertising={this.editAdminAdvertising}
        />

        <CustomLoadingBackdrop open={loading} />

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
          />
        </Portal>
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminPromocodesAdvertising)
);
// Customizable Area End
