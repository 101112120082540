import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { DashboardAdminStatistics } from "../../../components/src/KitchenDashboardStatisticsCard.web";
import { order, restaurant, user, revenue, cancelation, home } from "./assets";
import { v4 as uuidv4 } from "uuid";
import { AdminRestaurantStatus } from "../../../components/src/AdminRestaurantStatusTag.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { AdminDashboardDetailFormDialogProps } from "../../../components/src/AdminDashboardDetailFormDialog.web";
import { WithTranslation } from "react-i18next";

export interface StatictisDisplayState {
  totalRestaurants: boolean;
  activeUsers: boolean;
  totalOrders: boolean;
  totalSales: boolean;
  registeredRestaurants: boolean;
  totalCancellation: boolean;
  activeAdmins: boolean;
}

export type StatisticsKey =
  | "totalRestaurants"
  | "activeUsers"
  | "totalOrders"
  | "totalSales"
  | "registeredRestaurants"
  | "totalCancellation"
  | "activeAdmins";

interface StatictisDisplayStates {
  totalRestaurants: StatictisDisplayState;
  activeUsers: StatictisDisplayState;
  totalOrders: StatictisDisplayState;
  totalSales: StatictisDisplayState;
  registeredRestaurants: StatictisDisplayState;
  totalCancellation: StatictisDisplayState;
  activeAdmins: StatictisDisplayState;
  all: StatictisDisplayState;
}

export interface StatisticsDataProps {
  totalRestaurants: DashboardAdminStatistics;
  activeUsers: DashboardAdminStatistics;
  totalOrders: DashboardAdminStatistics;
  registeredRestaurants: DashboardAdminStatistics;
  totalSales: DashboardAdminStatistics;
  totalCancellation: DashboardAdminStatistics;
  activeAdmins: DashboardAdminStatistics;
}

export interface SalesDetailProps {
  name: string;
  value: number;
  color: string;
}

export interface SalesDetailDataProps {
  totalOrder: SalesDetailProps;
  runningOrders: SalesDetailProps;
  customerGrowth: SalesDetailProps;
  totalRevenue: SalesDetailProps;
}

export enum FetchStatisticsDataTask {
  TotalRestaurants = "TotalRestaurants",
  ActiveUsers = "ActiveUsers",
  TotalOrders = "TotalOrders",
  RegisteredRestaurants = "RegisteredRestaurants",
  TotalRevenue = "TotalRevenue",
  TotalCancellation = "TotalCancellation",
  ActiveAdmins = "ActiveAdmins",
}
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  revenueChartData: { title: string; value: number }[];
  totalRevenue: number;
  revenuePercentage: number;
  statisticsData: StatisticsDataProps;
  revenueFilter: string;
  salesFilter: number;
  restaurantData: {
    id: string | number;
    name: string;
    branchName: string;
    location: string;
    address: string;
    staff: {
      name: string;
      role: string;
    };
    contactInfo: string;
    registrationDate: string | number;
    status: AdminRestaurantStatus;
  }[];
  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };
  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
  isSortDesc: boolean;
  sortType: string;

  adminDashboardDetailDialog: AdminDashboardDetailFormDialogProps;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class DashboardAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fetchRestaurantDataApiId: string = "";
  fetchRevenueChartDataApiId: string = "";
  fetchSalesDetailChartDataApiId: string = "";
  fetchTotalOrderRevenueApiId: string = "";

  fetchStatisticsDataApi: {
    [id: string]: {
      task: FetchStatisticsDataTask;
      message: Message;
    };
  } = {};

  timeOptions = [
    {
      id: uuidv4(),
      name: "Weekly",
      value: "weekly",
    },
    {
      id: uuidv4(),
      name: "Monthly",
      value: "monthly",
    },
    {
      id: uuidv4(),
      name: "Yearly",
      value: "yearly",
    },
  ];

  statictisDefaultData: StatisticsDataProps = {
    totalRestaurants: {
      name: "Total Restaurants",
      icon: restaurant,
      theme: "orange",
      value: 0,
      display: true,
    },

    activeUsers: {
      name: "Active Users",
      icon: user,
      theme: "blue",
      value: 0,
      display: true,
    },

    totalOrders: {
      name: "Total Orders",
      icon: order,
      theme: "yellow",
      value: 0,
      display: true,
    },

    registeredRestaurants: {
      name: "Registered Restaurants",
      icon: home,
      theme: "red",
      value: 0,
      display: true,
    },

    totalSales: {
      name: "Total Sales",
      icon: revenue,
      theme: "green",
      value: this.props.t("$ {{number}}", { number: 0 }),
      display: true,
    },

    totalCancellation: {
      name: "Total Cancellation",
      icon: cancelation,
      theme: "red",
      value: 0,
      display: true,
    },

    activeAdmins: {
      name: "Active Admins",
      icon: user,
      theme: "blue",
      value: 0,
      display: true,
    },
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      loading: false,
      revenueChartData: [],
      totalRevenue: 0,
      revenuePercentage: 0,
      revenueFilter: "weekly",
      salesFilter: 7,
      statisticsData: this.statictisDefaultData,
      restaurantData: [],
      pagination: {
        page: 1,
        limit: 10,
        totalPages: 0,
        totalCount: 0,
      },
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
      isSortDesc: true,
      sortType: "desc",

      adminDashboardDetailDialog: {
        open: false,
        loading: false,
        form: {
          id: "",
          restaurantName: "",
          branch: "",
          address: "",
          staffName: "",
          role: "",
          status: AdminRestaurantStatus.Open,
        },
        roleOptions: [],
      },
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.fetchStatisticsData();
    this.fetchRestaurantData();
    this.fetchRevenueChartData();
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props) {
    if (this.props.navigation !== prevProps.navigation) {
      this.filterStatictisCards();
    }
  }

  createAdminDashboardMessage = async (data: {
    url: string;
    method: string;
  }): Promise<Message> => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const adminMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      data.url
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    adminMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      data.method
    );

    return adminMessage;
  };

  changePage = (page: number) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
        },
      },
      () => {
        this.fetchRestaurantData();
      }
    );
  };

  filterStatictisCards = () => {
    const filterValue = this.props.navigation
      .selectedDashboardFilter as keyof StatisticsDataProps;

    const displayStates: StatictisDisplayStates = {
      totalRestaurants: this.createDisplayState("totalRestaurants"),
      activeUsers: this.createDisplayState("activeUsers"),
      totalOrders: this.createDisplayState("totalOrders"),
      totalSales: this.createDisplayState("totalSales"),
      registeredRestaurants: this.createDisplayState("registeredRestaurants"),
      totalCancellation: this.createDisplayState("totalCancellation"),
      activeAdmins: this.createDisplayState("activeAdmins"),

      all: {
        totalRestaurants: true,
        activeUsers: true,
        totalOrders: true,
        totalSales: true,
        registeredRestaurants: true,
        totalCancellation: true,
        activeAdmins: true,
      },
    };

    const selectedDisplayState = displayStates[filterValue] || {
      totalRestaurants: false,
      activeUsers: false,
      totalOrders: false,
      totalSales: false,
      registeredRestaurants: false,
      totalCancellation: false,
      activeAdmins: false,
    };

    this.setState((prevState) => ({
      statisticsData: {
        ...prevState.statisticsData,
        totalRestaurants: {
          ...prevState.statisticsData.totalRestaurants,
          display: selectedDisplayState.totalRestaurants,
        },

        activeUsers: {
          ...prevState.statisticsData.activeUsers,
          display: selectedDisplayState.activeUsers,
        },

        totalOrders: {
          ...prevState.statisticsData.totalOrders,
          display: selectedDisplayState.totalOrders,
        },

        totalSales: {
          ...prevState.statisticsData.totalSales,
          display: selectedDisplayState.totalSales,
        },

        registeredRestaurants: {
          ...prevState.statisticsData.registeredRestaurants,
          display: selectedDisplayState.registeredRestaurants,
        },

        totalCancellation: {
          ...prevState.statisticsData.totalCancellation,
          display: selectedDisplayState.totalCancellation,
        },

        activeAdmins: {
          ...prevState.statisticsData.activeAdmins,
          display: selectedDisplayState.activeAdmins,
        },
      },
    }));
  };

  createDisplayState = (activeKey: StatisticsKey): StatictisDisplayState => {
    const displayState: StatictisDisplayState = {
      totalRestaurants: false,
      activeUsers: false,
      totalOrders: false,
      totalSales: false,
      registeredRestaurants: false,
      totalCancellation: false,
      activeAdmins: false,
    };

    displayState[activeKey] = true;

    return displayState;
  };

  fetchStatisticsData = async () => {
    const totalRestaurantsMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/account_block/accounts/kitchen_account_count",
    });

    const activeUsersMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/account_block/accounts/sms_account_count",
    });

    const totalOrdersMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/bx_block_order_management/orders/total_order_count",
    });

    const registeredRestaurantsMessage = await this.createAdminDashboardMessage(
      {
        method: "GET",
        url: "/bx_block_order_management/orders/reg_restaurants_count",
      }
    );

    const totalRevenueMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/bx_block_order_management/orders/total_revenue_count",
    });

    const totalCancellationMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/bx_block_order_management/orders/total_cancellation_orders",
    });

    const adminUsersMessage = await this.createAdminDashboardMessage({
      method: "GET",
      url: "/bx_block_order_management/orders/active_users_count",
    });

    this.fetchStatisticsDataApi = {
      [totalRestaurantsMessage.messageId]: {
        task: FetchStatisticsDataTask.TotalRestaurants,
        message: totalRestaurantsMessage,
      },

      [activeUsersMessage.messageId]: {
        task: FetchStatisticsDataTask.ActiveUsers,
        message: activeUsersMessage,
      },

      [totalOrdersMessage.messageId]: {
        task: FetchStatisticsDataTask.TotalOrders,
        message: totalOrdersMessage,
      },

      [registeredRestaurantsMessage.messageId]: {
        task: FetchStatisticsDataTask.RegisteredRestaurants,
        message: registeredRestaurantsMessage,
      },

      [totalRevenueMessage.messageId]: {
        task: FetchStatisticsDataTask.TotalRevenue,
        message: totalRevenueMessage,
      },

      [totalCancellationMessage.messageId]: {
        task: FetchStatisticsDataTask.TotalCancellation,
        message: totalCancellationMessage,
      },

      [adminUsersMessage.messageId]: {
        task: FetchStatisticsDataTask.ActiveAdmins,
        message: adminUsersMessage,
      },
    };

    Object.keys(this.fetchStatisticsDataApi).forEach((key) => {
      runEngine.sendMessage(key, this.fetchStatisticsDataApi[key].message);
    });

    this.setState({
      loading: true,
    });
  };

  handleFetchStatisticsData = (
    task: FetchStatisticsDataTask,
    responseJson: any
  ) => {
    if (responseJson.errors || responseJson.error) {
      this.fetchStatisticsDataApi = {};
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    switch (task) {
      case FetchStatisticsDataTask.TotalRestaurants:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            totalRestaurants: {
              ...this.state.statisticsData.totalRestaurants,
              value: responseJson.kitchen_account_count,
            },
          },
        });

        break;

      case FetchStatisticsDataTask.ActiveUsers:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            activeUsers: {
              ...this.state.statisticsData.activeUsers,
              value: responseJson.sms_account_count,
            },
          },
        });

        break;

      case FetchStatisticsDataTask.TotalOrders:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            totalOrders: {
              ...this.state.statisticsData.totalOrders,
              value: responseJson.orders_count,
            },
          },
        });

        break;

      case FetchStatisticsDataTask.RegisteredRestaurants:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            registeredRestaurants: {
              ...this.state.statisticsData.registeredRestaurants,
              value: responseJson.registered_restaurants_count,
            },
          },
        });

        break;

      case FetchStatisticsDataTask.TotalRevenue:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            totalSales: {
              ...this.state.statisticsData.totalSales,
              value: this.props.t("$ {{number}}", {
                number: responseJson.revenue_count,
              }),
            },
          },
        });

        break;

      case FetchStatisticsDataTask.TotalCancellation:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            totalCancellation: {
              ...this.state.statisticsData.totalCancellation,
              value: responseJson.total_cancellation_orders_count,
            },
          },
        });

        break;

      case FetchStatisticsDataTask.ActiveAdmins:
        this.setState({
          statisticsData: {
            ...this.state.statisticsData,
            activeAdmins: {
              ...this.state.statisticsData.activeAdmins,
              value: responseJson.active_admin_count,
            },
          },
        });

        break;

      default:
        break;
    }

    if (Object.keys(this.fetchStatisticsDataApi).length) {
      return;
    }

    this.setState({
      loading: false,
    });
  };

  fetchRestaurantData = async () => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchRestaurantDataApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_location/stores/listing_of_branches?page=${this.state.pagination.page}&per_page=${this.state.pagination.limit}&sort_order=${this.state.sortType}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchRestaurantData = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const restaurantData = responseJson.branches.data.map((store: any) => ({
      id: store.id,
      name: store.attributes.name,
      branchName: store.attributes.branch_name,
      location: store.attributes.location,
      address: store.attributes.address,
      staff: {
        name: store.attributes.kitchen_account.user_name,
        role: "manager"
      },
      contactInfo: store.attributes.contact_info,
      registrationDate: store.attributes.created_at,
      status: store.attributes.status,
    }));

    const pagination = {
      limit: 10,
      page: responseJson.pagination.current_page,
      totalCount: responseJson.pagination.total_count,
      totalPages: responseJson.pagination.total_pages,
    };

    this.setState({ restaurantData, pagination });
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  mapStatisticsData = (
    data: [key: string, value: DashboardAdminStatistics][]
  ) => {
    return data.map(([key, value]) => {
      return {
        name: this.props.t(value.name),
        value: value.value,
        theme: value.theme,
        icon: value.icon,
        display: value.display,
      };
    });
  };

  handleSort = () => {
    const isSortDesc = !this.state.isSortDesc;

    this.setState(
      {
        isSortDesc,
        sortType: isSortDesc ? "desc" : "asc",
      },
      this.fetchRestaurantData
    );
  };

  fetchRevenueChartData = async () => {
    const token = await StorageProvider.get("token");

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const header = {
      "Content-Type": "application/json",
      token,
    };

    this.fetchTotalOrderRevenueApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_order_management/orders/total_order_revenue?filter=${this.state.revenueFilter}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  handleFetchTotalOrderRevenue = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.renderChartData(responseJson);
  };

  renderChartData = (chartData: any) => {
    const type = this.state.revenueFilter;
    let title = "";

    switch (type) {
      case "weekly":
        title = "day";
        break;

      case "monthly":
        title = "month";
        break;

      case "yearly":
        title = "year";
        break;

      default:
        break;
    }

    const revenueChartData = chartData.data.map((item: any) => ({
      title: item[title],
      value: item.orders_revenue_count,
    }));

    this.setState({
      revenueChartData,
      totalRevenue: chartData.total_revenue_count,
      revenuePercentage: chartData.revenue_percentage,
    });
  };

  handleChangeRevenueFilter = (selectedOption: string) => {
    this.setState(
      {
        revenueFilter: selectedOption,
      },
      this.fetchRevenueChartData
    );
  };

  openAdminBranchDetailDialog = (data: any) => {
    this.setState({
      adminDashboardDetailDialog: {
        ...this.state.adminDashboardDetailDialog,
        open: true,
        form: {
          id: data.id,
          restaurantName: data.name,
          branch: data.branchName,
          staffName: data.staff.name,
          address: data.address,
          role: data.staff.role,
          status: data.status
        }
      },
    });
  };

  closeAdminDashboardDetailDialog = () => {
    this.setState({
      adminDashboardDetailDialog: {
        open: false,
        loading: false,
        form: {
          id: "",
          restaurantName: "",
          branch: "",
          address: "",
          staffName: "",
          role: "",
          status: AdminRestaurantStatus.Open,
        },
        roleOptions: [],
      },
    });
  };

  formatRevenuePercentage = (value: number) => {
    return value < 0 ? value : `+${value}`;
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      Object.keys(this.fetchStatisticsDataApi).length &&
      Object.keys(this.fetchStatisticsDataApi).includes(apiRequestCallId)
    ) {
      const task = this.fetchStatisticsDataApi[apiRequestCallId].task;

      delete this.fetchStatisticsDataApi[apiRequestCallId];

      this.handleFetchStatisticsData(task, responseJson);
    }

    switch (apiRequestCallId) {
      case this.fetchRestaurantDataApiId:
        this.handleFetchRestaurantData(responseJson);

        break;

      case this.fetchRevenueChartDataApiId:
        this.changePage(1);

        break;

      case this.fetchSalesDetailChartDataApiId:
        this.changePage(2);
        break;

      case this.fetchTotalOrderRevenueApiId:
        this.handleFetchTotalOrderRevenue(responseJson);

        break;

      default:
        break;
    }
    // Customizable Area End
  }
}
