import React from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { customLocaleMoment } from "../Utils.web";
import { Box, makeStyles } from "@material-ui/core";

type Props = {
  data: { period: number; currentPeriod: number; previousPeriod: number }[];
  total: number;
};

export default function CustomAnalyticsAdminSalesCompareChart({
  data,
  total,
}: Props) {
  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  customLocaleMoment.locale(i18n.language);

  const classes = useStyles();

  return (
    <>
      <h3 className={classes.title} dir={dir}>
        $&rlm;{total}
      </h3>
      <ResponsiveContainer maxHeight={252} className={classes.chartContainer}>
        <BarChart data={data} reverseStackOrder={rtl} barGap={0} barSize={10}>
          <Legend
            align="center"
            verticalAlign="top"
            height={45}
            iconType="circle"
            iconSize={10}
            wrapperStyle={{
              fontFamily: "Inter",
              fontSize: "12px",
              lineHeight: 1.3,
              fontWeight: 700,
              textAlign: rtl ? "right" : "left",
              color: "#767676",
            }}
            payload={[
              {
                value: t("Current Period"),
                type: "circle",
                id: "currentPeriod",
                dataKey: "currentPeriod",
              },
              {
                value: t("Previous Period"),
                type: "circle",
                id: "previousPeriod",
                dataKey: "previousPeriod",
              },
            ]}
          />
          <CartesianGrid
            strokeDasharray="1000"
            vertical={false}
            stroke="#B1B1B1"
            strokeWidth={0.5}
          />
          <Bar dataKey={"currentPeriod"} fill="#FF6666" radius={2.8} />
          <Bar dataKey={"previousPeriod"} fill="#059669" radius={2.8} />
          <YAxis
            width={25}
            axisLine={false}
            tickLine={false}
            tickCount={3}
            tick={{
              fontSize: "10px",
              fontWeight: 700,
              fontFamily: "Inter",
              fill: "#767676",
              dx: rtl ? 10 : 0,
            }}
            tickFormatter={(value) => `$\u200E` + value}
            orientation={rtl ? "right" : "left"}
          />
          <XAxis
            dataKey="period"
            interval={0}
            tick={{
              fontFamily: "Inter",
              fontWeight: 700,
              fill: "#767676",
              fontSize: "10px",
            }}
            tickLine={false}
            axisLine={false}
            reversed={rtl}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    title: {
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(24),
      fontWeight: 700,
      lineHeight: 29 / 24,
      marginBlock: 0,
      textAlign: "start",
    },

    chartContainer: {
      "& span.recharts-legend-item-text": {
        color: "#767676",
      },
      "& li.recharts-legend-item > svg.recharts-surface": {
        marginInlineEnd: 4,
      },
      "& li.legend-item-0 > svg.recharts-surface > path.recharts-symbols": {
        fill: "#FF6666",
      },
      "& li.legend-item-1 > svg.recharts-surface > path.recharts-symbols": {
        fill: "#059669",
      },
    },
  };
});
