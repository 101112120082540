import {
  Box,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { RemoveRedEyeOutlined } from "@material-ui/icons";
import React from "react";
import clsx from "clsx";
import { WithTranslation, withTranslation } from "react-i18next";
import CustomTypography from "./CustomTypography.web";

const iconThumbUp = require("./ic_thumb_up.svg");
const iconThumbDown = require("./ic_thumb_down.svg");

export interface AdmindvertisingEngagement {
  views: number;
  likes: {
    number: number;
    type: "like" | "dislike";
  };
}

export interface Props extends WithStyles, WithTranslation {
  engagement: AdmindvertisingEngagement;
}

const styles = (theme: Theme) =>
  createStyles({
    engagement: {
      display: "flex",
    },

    content: {
      display: "flex",
    },

    text: {
      fontFamily: "Inter !important",
      margin: 5,
    },

    icon: {
      width: 20,
      height: 20,
      margin: 5,
    },
  });

export class AdminPromocodesAdvertisingEngagement extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, engagement, t, i18n } = this.props;
    const rtl = i18n.language === "ar";

    const likeIcon =
      engagement.likes.type === "like" ? iconThumbUp : iconThumbDown;

    return (
      <Box
        className={clsx(classes.engagement, {
          ["rtl"]: rtl,
        })}
      >
        <Box className={classes.content}>
          <RemoveRedEyeOutlined className={classes.icon} />

          <CustomTypography className={classes.text}>
            {engagement.views}
          </CustomTypography>
        </Box>

        <Box className={classes.content}>
          <img className={classes.icon} src={likeIcon} />

          <CustomTypography className={classes.text}>
            {engagement.likes.number}
          </CustomTypography>
        </Box>
      </Box>
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminPromocodesAdvertisingEngagement)
);
