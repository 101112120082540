// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import React, { KeyboardEvent } from "react";

import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { AdminPromocodesAdvertisingStatus } from "../../../components/src/AdminPromocodesAdvertisingStatusTag.web";
import {
  AdminPromocodesAdvertisingDetailFormDialogProps,
  AdminPromocodesAdvertising,
} from "../../../components/src/AdminPromocodesAdvertisingDetailFormDialog.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import moment from "moment";
import { AdmindvertisingEngagement } from "../../../components/src/AdminPromocodesAdvertisingEngagement.web";

export const configJSON = require("./config");

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;

  advertisingList: AdminPromocodesAdvertising[];

  timeFilter: {
    value: string;
    isDate: boolean;
  };

  restaurantFilter: string;

  requestIdSearch: string;

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  adminAdvertisingDetailDialog: AdminPromocodesAdvertisingDetailFormDialogProps;
}

interface SS {}

export default class AdminPromocodesAdvertisingController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchAdvertisingListApiId: string = "";
  downloadAdvertisingListApiId: string = "";
  updateAdvertisingApiId: string = "";
  stopAdvertisingApiId: string = "";

  inputRef: React.RefObject<HTMLInputElement>;

  timeOptions = [
    {
      id: uuidv4(),
      name: "Today",
      value: moment().format("YYYY-MM-DD"),
      isDate: true,
    },

    {
      id: uuidv4(),
      name: "Yesterday",
      value: moment().subtract(1, "days").format("YYYY-MM-DD"),
      isDate: true,
    },

    {
      id: uuidv4(),
      name: "Last 7 days",
      value: "last_7_days",
      isDate: false,
    },

    {
      id: uuidv4(),
      name: "Last 30 days",
      value: "last_month",
      isDate: false,
    },

    {
      id: uuidv4(),
      name: "All",
      value: "",
      isDate: false,
    },
  ];

  restaurantOptions = [
    {
      id: uuidv4(),
      name: "Restaurant",
      value: "restaurant",
    },

    {
      id: uuidv4(),
      name: "Branch",
      value: "branch",
    },
  ];

  adminAdvertisingDetailDialogDefaultValue: AdminPromocodesAdvertisingDetailFormDialogProps =
    {
      open: false,
      loading: false,
      form: {
        requestId: "",
        restaurantName: "",
        startDate: new Date(),
        endDate: new Date(),
        engagement: {
          likes: {
            number: 0,
            type: "like",
          },
          views: 0,
        },
        revenue: 0,
        status: AdminPromocodesAdvertisingStatus.Active,
      },
      mode: "view",
    };

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,

      advertisingList: [],

      timeFilter: { value: "last_7_days", isDate: false },

      restaurantFilter: "restaurant",

      requestIdSearch: "",

      pagination: {
        page: 1,
        totalPages: 0,
        totalCount: 0,
        limit: 10,
      },

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },

      adminAdvertisingDetailDialog:
        this.adminAdvertisingDetailDialogDefaultValue,
    };

    this.inputRef = React.createRef();
  }

  async componentDidMount(): Promise<void> {
    this.fetchAdvertisingList();
  }

  fetchAdvertisingList = async () => {
    const queryParams = new URLSearchParams();

    queryParams.append("page", this.state.pagination.page.toString());
    queryParams.append("per_page", this.state.pagination.limit.toString());
    queryParams.append("per_page", this.state.pagination.limit.toString());
    queryParams.append("id", this.state.requestIdSearch);

    if (this.state.timeFilter.isDate) {
      queryParams.append("start_date", this.state.timeFilter.value);
      queryParams.append("end_date", this.state.timeFilter.value);
    } else {
      queryParams.append("filter", this.state.timeFilter.value);
    }

    const endpoint =
      configJSON.getAdminAdvertisingListEndpoint + "?" + queryParams.toString();

    this.callApi("fetchAdvertisingListApiId", endpoint, "GET");
  };

  handleFetchAdvertisingList = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const advertisingList = responseJson.advertisements.data.map(
      (item: any) => ({
        requestId: item.id,
        restaurantName: "",
        startDate: item.attributes.start_date,
        endDate: item.attributes.end_date,
        engagement: {
          views: item.attributes.engagement_view_rate,
          likes: {
            number: 0,
            type: "like",
          },
        },
        revenue: item.attributes.revenue,
        status: item.attributes.running_status
          ? AdminPromocodesAdvertisingStatus.Active
          : AdminPromocodesAdvertisingStatus.InActive,
      })
    );

    this.setState({
      advertisingList,
    });
  };

  editAdminAdvertising = async (values: {
    requestId: string;
    restaurantName: string;
    startDate: Date;
    endDate: Date;
    engagement: AdmindvertisingEngagement;
    revenue: number;
    status: AdminPromocodesAdvertisingStatus;
  }) => {
    this.setState({
      adminAdvertisingDetailDialog: {
        ...this.state.adminAdvertisingDetailDialog,
        loading: true,
      },
    });

    const endpoint = `${configJSON.updateAdvertisingEndpoint}/${values.requestId}`;

    const body = {
      start_date: values.startDate,
      end_date: values.endDate,
      running_status: values.status === AdminPromocodesAdvertisingStatus.Active,
    };

    await this.callApi("updateAdvertisingApiId", endpoint, "PATCH", body);
  };

  handleUpdateAdvertising = (responseJson: any) => {
    this.setState({
      loading: false,
      adminAdvertisingDetailDialog: {
        ...this.state.adminAdvertisingDetailDialog,
        loading: false,
      },
    });

    if (responseJson.errors) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    if (responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, responseJson.error);

      return;
    }

    this.fetchAdvertisingList();
    this.closeAdminAdvertisingDetailDialog();
  };

  handleSearch = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key !== "Enter") {
      return;
    }

    event.preventDefault();

    const target = event.target as HTMLInputElement;

    this.setState(
      {
        requestIdSearch: target.value,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdvertisingList();
      }
    );
  };

  clearRequestIdSearch = () => {
    if (this.inputRef.current) {
      this.inputRef.current.value = "";
    }

    this.setState(
      {
        requestIdSearch: "",
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
      },
      () => {
        this.fetchAdvertisingList();
      }
    );
  };

  downloadReport = () => {
    this.callApi(
      "downloadAdvertisingListApiId",
      configJSON.downloadAdminAdvertisingReport,
      "GET"
    );
  };

  handleDownloadReport = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(
        CustomSnackbarType.Error,
        "Download failed! Please try again"
      );

      return;
    }

    const link = document.createElement("a");

    link.href = responseJson.download_url;

    link.download = "order-report.pdf";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  openAdminAdvertisingDetailDialog = (
    advertising: AdminPromocodesAdvertising,
    mode: string
  ) => {
    this.setState({
      adminAdvertisingDetailDialog: {
        ...this.state.adminAdvertisingDetailDialog,
        open: true,
        form: advertising,
        mode,
      },
    });
  };

  closeAdminAdvertisingDetailDialog = () => {
    this.setState({
      adminAdvertisingDetailDialog:
        this.adminAdvertisingDetailDialogDefaultValue,
    });
  };

  changeRestaurantFilter = (value: any) => {
    this.setState({
      restaurantFilter: value,
    });
  };

  changeTimeFilter = (value: any, option: any) => {
    this.setState(
      {
        timeFilter: {
          value,
          isDate: option.isDate,
        },
      },
      () => {
        this.fetchAdvertisingList();
      }
    );
  };

  handleMenuActions = (action: any, data: AdminPromocodesAdvertising) => {
    switch (action) {
      case "view":
        this.openAdminAdvertisingDetailDialog(data, "view");

        break;

      case "edit":
        this.openAdminAdvertisingDetailDialog(data, "edit");

        break;

      case "stopAdvertising":
        this.stopAdvertising(data.requestId);

        break;

      default:
        break;
    }
  };

  stopAdvertising = async (id: string) => {
    const endpoint = `${configJSON.updateAdvertisingEndpoint}/${id}`;

    const body = {
      running_status: false,
    };

    await this.callApi("stopAdvertisingApiId", endpoint, "PATCH", body);
  };

  handleStopAdvertising = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    if (responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, responseJson.error);

      return;
    }

    this.fetchAdvertisingList();
  };

  callApi = async (
    api: string,
    endpoint: string,
    httpMethod: string,
    body?: any
  ) => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    switch (api) {
      case "fetchAdvertisingListApiId":
        this.fetchAdvertisingListApiId = requestMessage.messageId;

        break;

      case "downloadAdvertisingListApiId":
        this.downloadAdvertisingListApiId = requestMessage.messageId;

        break;

      case "updateAdvertisingApiId":
        this.updateAdvertisingApiId = requestMessage.messageId;

        break;

      case "stopAdvertisingApiId":
        this.stopAdvertisingApiId = requestMessage.messageId;

        break;

      default:
        break;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      httpMethod
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);

    this.setState({
      loading: true,
    });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchAdvertisingListApiId:
        this.handleFetchAdvertisingList(responseJson);

        break;

      case this.updateAdvertisingApiId:
        this.handleUpdateAdvertising(responseJson);

        break;

      case this.downloadAdvertisingListApiId:
        this.handleDownloadReport(responseJson);

        break;

      case this.stopAdvertisingApiId:
        this.handleStopAdvertising(responseJson);

        break;

      default:
        break;
    }
  }
}

// Customizable Area End
