import React from "react";

import { styled } from "@material-ui/core/styles";

import CustomTypography from "../../../components/src/CustomTypography.web";

import { DeleteOutline, MoreVert } from "@material-ui/icons";

import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";

type Props = {
  onDelete(): void;
};

export default function AdvertisingTableActionMenu({ onDelete }: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";

  return (
    <>
      <IconButton
        aria-label="Toggle Advertising Table Action Menu"
        aria-controls="AdvertisingTableActionMenu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <StyledMenu
        id="AdvertisingTableActionMenu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: "left", vertical: "center" }}
        transformOrigin={{ horizontal: "center", vertical: "center" }}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem
          onClick={() => {
            onDelete();
            handleClose();
          }}
          dir={dir}
        >
          <DeleteOutline />
          <CustomTypography>{t("Delete")}</CustomTypography>
        </MenuItem>
      </StyledMenu>
    </>
  );
}

const StyledMenu = styled(Menu)({
  "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-rounded": {
    paddingBlock: 0,
    width: 120,
    height: 36,
    borderRadius: 8,
  },
  "& .MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.MuiListItem-button":
    {
      gap: 8,
    },
});
