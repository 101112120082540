import React from "react";
import { Box, styled } from "@material-ui/core";
import CustomTypographyWeb from "../CustomTypography.web";
import { useTranslation } from "react-i18next";
import CustomButton from "../CustomButton.web";
import { imgInfo } from "../../../blocks/promocodes/src/assets";
import CustomLoadingBackdropWeb from "../CustomLoadingBackdrop.web";

type Props = {
  type: "tnc" | "pp";
  onCancel(): void;
  onAgree(): void;
  content: { id: string; description: string }[];
  loading: boolean;
};

export const Container = styled(Box)(({ theme }) => {
  return {
    paddingInlineEnd: 24,
    "& img.banner": {
      maxWidth: "100%",
      width: "100%",
      maxHeight: 221,
      objectFit: "cover",
      objectPosition: "0 24%",
    },
    "& .title": {
      marginBlock: 24,
    },
    "& .content": {
      whiteSpace: "pre-wrap",
      marginBlockStart: 16,
      color: "#0F172A",
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 24 / 16,
    },
    "& .actions": {
      "& button": {
        maxWidth: 196,
        height: 44,
      },
    },
  };
});

export default function CustomPromocodesAdvertisingInfoPage({
  type,
  content,
  loading,
  onAgree,
  onCancel,
}: Props) {
  const { t, i18n } = useTranslation();

  const rtl = i18n.language === "ar";
  const dir = rtl ? "rtl" : "ltr";
  const title =
    type === "tnc" ? t("Terms and Conditions") : t("Privacy policy");

  return (
    <Container dir={dir}>
      <CustomLoadingBackdropWeb open={loading} />
      <img src={imgInfo} className="banner" />
      <CustomTypographyWeb variant="h2" className="title">
        {title}
      </CustomTypographyWeb>

      {content.map((item) => {
        return (
          <CustomTypographyWeb
            key={item.id}
            className="content"
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        );
      })}

      <Box
        display={"flex"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        dir={dir}
        mt={14}
        className="actions"
        gridGap={24}
      >
        <CustomButton
          variant="contained"
          color="primary"
          dir={dir}
          fullWidth
          onClick={onCancel}
          data-variant="cancel"
        >
          {t("Cancel")}
        </CustomButton>
        <CustomButton
          dir={dir}
          fullWidth
          variant="contained"
          color="primary"
          onClick={onAgree}
        >
          {t("Agree")}
        </CustomButton>
      </Box>
    </Container>
  );
}
