import React from "react";
import {
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import CustomTable from "../CustomTable";
import { ArrowUpward } from "@material-ui/icons";

type Props = {
  data: {
    id: string;
    menuItem: string;
    percentage: string;
    subtotal: number;
  }[];
};

function CustomAnalyticsAdminSalesMenuItemTable({ data }: Props) {
  const { t, i18n } = useTranslation();
  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  const classes = useStyles();

  return (
    <TableContainer>
      <CustomTable dir={dir} className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t("ID")}</TableCell>
            <TableCell>{t("Menu Item")}</TableCell>
            <TableCell>{t("Percentage")}</TableCell>
            <TableCell>{t("Subtotal")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            return (
              <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.menuItem}</TableCell>
                <TableCell>
                  <ArrowUpward fontSize="inherit" htmlColor="#F87171" />{" "}
                  {item.percentage}&rlm;%
                </TableCell>
                <TableCell>
                  {t("SAR")} {item.subtotal}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    table: {
      "& tr td, & tr td": {
        padding: 12,
      },
      "& tr th:last-child, & tr td:last-child": {
        textAlign: "end",
      },
      "& tr td:nth-child(3), & tr td:nth-child(4)": {
        fontFamily: "Inter",
      },

      "& tr th:first-child,& tr td:first-child": {
        width: "auto",
      },

      "@media(min-width: 1600px)": {
        "& tr th:first-child,& tr td:first-child, & tr th:last-child,& tr td:last-child":
          {
            width: "35%",
          },
      },

      "@media(min-width: 1440px)": {
        "& tr th:first-child,& tr td:first-child, & tr th:last-child,& tr td:last-child":
          {
            width: "30%",
          },
      },
    },
  };
});

export default CustomAnalyticsAdminSalesMenuItemTable;
