// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";

import { WithStyles } from "@material-ui/core";
import { WithTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { OrderHelp } from "../../../components/src/KitchenOrderHelpFormDrawer.web";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

const defautReviewData = {
  id: "",
  title: "",
  content: "",
  date: "",
  kitchenReplies: [],
};

export interface Props extends WithStyles, WithTranslation {
  navigation: any;
  id: string;
}

interface S {
  loading: boolean;

  ratingLevelFilter: number;

  dateFilter: string;

  isShowAll: boolean;

  listOrderHelp: OrderHelp[];

  orderHelpDrawer: {
    review: OrderHelp;
    loadingReply: boolean;
    open: boolean;
    replyContent: string;
  };

  pagination: {
    page: number;
    totalPages: number;
    totalCount: number;
    limit: number;
  };

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };
}

interface SS {}

export default class OrderHelpController extends BlockComponent<Props, S, SS> {
  fetchOrderHelpListApiId: string = "";
  replyUserApiId: string = "";

  ratingLevelOptions = [
    {
      id: uuidv4(),
      name: "All",
      value: 0,
    },

    {
      id: uuidv4(),
      name: "5",
      value: 5,
    },

    {
      id: uuidv4(),
      name: "4",
      value: 4,
    },

    {
      id: uuidv4(),
      name: "3",
      value: 3,
    },

    {
      id: uuidv4(),
      name: "2",
      value: 2,
    },

    {
      id: uuidv4(),
      name: "1",
      value: 1,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      loading: false,

      ratingLevelFilter: -1,

      dateFilter: "",

      isShowAll: true,

      listOrderHelp: [],

      orderHelpDrawer: {
        review: defautReviewData,
        loadingReply: false,
        open: false,
        replyContent: "",
      },

      pagination: {
        page: 1,
        totalPages: 0,
        totalCount: 0,
        limit: 6,
      },

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    };
  }

  async componentDidMount(): Promise<void> {
    this.fetchAllOrderHelp();
  }

  fetchAllOrderHelp = () => {
    const queryParams = new URLSearchParams();

    queryParams.append("page", this.state.pagination.page.toString());
    queryParams.append("per_page", this.state.pagination.limit.toString());
    queryParams.append("start_date", this.state.dateFilter);
    queryParams.append("end_date", this.state.dateFilter);

    const endPoint =
      configJSON.getAllOrderHelpEndpoint + "?" + queryParams.toString();

    this.callApi("fetchOrderHelpListApiId", endPoint, configJSON.getApiMethod);

    this.setState({
      loading: true,
    });
  };

  handleFetchAllOrderHelp = (responseJson: any) => {
    this.setState({
      loading: false,
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    const listOrderHelp = responseJson.data.map((item: any) => ({
      id: item.id,
      title: item.attributes.help_option_title,
      content: item.attributes.help_details,
      date: item.attributes.help_created_at,
      kitchenReplies: item.attributes.kitchen_messages,
    }));

    this.setState({
      listOrderHelp,
      pagination: {
        limit: 6,
        page: responseJson.pagination.current_page,
        totalCount: responseJson.pagination.total_count,
        totalPages: responseJson.pagination.total_pages,
      },
    });
  };

  replyUser = (values: { reply: string }) => {
    const body = {
      content: values.reply,
    };

    const orderHelpId = this.state.orderHelpDrawer.review.id;

    const endPoint = configJSON.replyOrderHelpEndpoint.replace(
      ":id",
      orderHelpId
    );

    this.callApi("replyUserApiId", endPoint, configJSON.postApiMethod, body);

    this.setState({
      orderHelpDrawer: {
        ...this.state.orderHelpDrawer,
        loadingReply: true,
      },
    });
  };

  handleReplyUser = (responseJson: any) => {
    this.setState({
      orderHelpDrawer: {
        ...this.state.orderHelpDrawer,
        loadingReply: false,
      },
    });

    if (responseJson.errors || responseJson.error) {
      this.openSnackbar(CustomSnackbarType.Error, "Error! Please try again");

      return;
    }

    this.setState({
      orderHelpDrawer: {
        ...this.state.orderHelpDrawer,
        review: {
          ...this.state.orderHelpDrawer.review,
          kitchenReplies: [
            ...this.state.orderHelpDrawer.review.kitchenReplies,
            responseJson.data,
          ],
        },
        replyContent: "",
      },
    });
  };

  handleSwitchChange = () => {
    this.setState(
      {
        isShowAll: !this.state.isShowAll,
        dateFilter: ""
      },
      () => {
        this.fetchAllOrderHelp();
      }
    );
  };

  changeDate = (date: Date | null) => {
    const extractedDate = date
      ? `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
      : "";

    this.setState(
      {
        dateFilter: extractedDate,
        pagination: {
          ...this.state.pagination,
          page: 1,
        },
        isShowAll: extractedDate ? false : true,
      },
      () => {
        this.fetchAllOrderHelp();
      }
    );
  };

  openDrawer = (review: OrderHelp) => {
    this.setState({
      orderHelpDrawer: {
        ...this.state.orderHelpDrawer,
        open: true,
        review,
      },
    });
  };

  closeDrawer = () => {
    this.setState({
      orderHelpDrawer: {
        ...this.state.orderHelpDrawer,
        open: false,
      },
    });
  };

  getSelectedDate = (date: string) => {
    return date ? new Date(date) : null;
  };

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  callApi = async (
    api: string,
    endpoint: string,
    httpMethod: string,
    body?: any
  ) => {
    const token = await StorageProvider.get("token");

    const header = {
      "Content-Type": "application/json",
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    switch (api) {
      case "fetchOrderHelpListApiId":
        this.fetchOrderHelpListApiId = requestMessage.messageId;

        break;

      case "replyUserApiId":
        this.replyUserApiId = requestMessage.messageId;

        break;

      default:
        break;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      httpMethod
    );

    if (body) {
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    }

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    switch (apiRequestCallId) {
      case this.fetchOrderHelpListApiId:
        this.handleFetchAllOrderHelp(responseJson);

        break;

      case this.replyUserApiId:
        this.handleReplyUser(responseJson);

        break;

      default:
        break;
    }
  }
}

// Customizable Area End
