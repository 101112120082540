import {
  Avatar,
  Box,
  IconButton,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import React from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import CustomTypography from "./CustomTypography.web";
import { getCompactName } from "./Utils.web";
import { MoreVert } from "@material-ui/icons";

const styles = createStyles({
  container: {
    padding: "12px 20px",
    borderBottom: "1px solid #E2E8F0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },

  inner: {
    display: "flex",
    alignItems: "center",
    gap: 20,
  },

  name: {
    fontFamily: "Inter !important",
    fontSize: "13.1px !important",
    fontWeight: "700 !important" as any,
    lineHeight: "19.64px",
  },

  userId: {
    fontFamily: "Inter !important",
    fontSize: "11.46px !important",
    lineHeight: "18.01px !important",
    color: "#64748B !important",
  },
});

export interface Props extends WithStyles, WithTranslation {
  name: string;
  userId: string;
  avatar?: string;

  onSelectAction(action: string): void;
}

export class CustomUserItem extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, name, userId, avatar, onSelectAction } = this.props;

    return (
      <Box className={classes.container}>
        <Box className={classes.inner}>
          <Avatar className={classes.adminAppBarAvatar} src={avatar ?? ""}>
            {getCompactName(name)}
          </Avatar>

          <Box className={classes.info}>
            <CustomTypography className={classes.name}>{name}</CustomTypography>
            <CustomTypography
              className={classes.userId}
            >{`#${userId}`}</CustomTypography>
          </Box>
        </Box>

        <IconButton data-test-id="menu" onClick={() => onSelectAction("")}>
          <MoreVert />
        </IconButton>
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(CustomUserItem));
