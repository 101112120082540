// Customizable Area Start
import React from "react";

import Servicespecificsettingsadmin2OpeningTimesController, {
  Props,
} from "./Servicespecificsettingsadmin2OpeningTimesController.web";
import { Portal, styled } from "@material-ui/core";
import CustomTypographyWeb from "../../../components/src/CustomTypography.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomServicespecificsettingsadmin2OpeningTimesDrawer from "../../../components/src/servicesspecificsettingsadmin2/CustomServicespecificsettingsadmin2OpeningTimesDrawer.web";
import { imgSchedule } from "./assets";
import CustomLoadingBackdropWeb from "../../../components/src/CustomLoadingBackdrop.web";
import CustomSnackbarWeb from "../../../components/src/CustomSnackbar.web";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { weekdayMap } from "../../../components/src/Utils.web";

export class Servicespecificsettingsadmin2OpeningTimes extends Servicespecificsettingsadmin2OpeningTimesController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { t, i18n } = this.props;
    const { showDrawer, loading, schedules, snackbar } = this.state;
    const specialSchedules = schedules.filter(
      (schedule) => schedule.schedule_type === "special"
    );

    const regularSchedules = schedules.filter(
      (schedule) => schedule.schedule_type === "regular"
    );

    const initialDays = specialSchedules.map((schedule) => schedule.day);
    const initialStartTime = specialSchedules?.[0]?.start_time;
    const initialEndTime = specialSchedules?.[0]?.end_time;

    const rtl = i18n.language === "ar";

    return (
      <>
        <CustomLoadingBackdropWeb open={loading} />

        <Portal>
          <CustomSnackbarWeb
            open={snackbar.open}
            message={snackbar.message}
            type={snackbar.type}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            onClose={this.hideSnackbar}
          />
        </Portal>

        <CustomServicespecificsettingsadmin2OpeningTimesDrawer
          loading={loading}
          onClose={this.hideDrawer}
          onSubmit={this.handleFormSubmitSpecial}
          open={showDrawer}
          initialDays={initialDays}
          initialStartTime={initialStartTime}
          initialEndTime={initialEndTime}
        />

        <Container
          className={clsx({
            rtl,
          })}
        >
          <div className="scheduleListContainer">
            <CustomTypographyWeb variant="h4">
              {t("Regular Schedule")}
            </CustomTypographyWeb>
            <div>
              <div className="scheduleList__descriptionContainer">
                <CustomTypographyWeb className="scheduleList__description">
                  {t("This is the schedule you follow on a day to day basis")}
                </CustomTypographyWeb>
                <CustomButton
                  color="primary"
                  variant="text"
                  className="scheduleList__edit"
                  onClick={this.showDrawer}
                >
                  {t("Edit")}
                </CustomButton>
              </div>
              <ul className="scheduleList">
                {regularSchedules.length > 0 ? (
                  regularSchedules.map((schedule) => {
                    return (
                      <li
                        className="scheduleList__item"
                        key={schedule.id}
                        data-schedule-type={schedule.schedule_type}
                      >
                        <CustomTypographyWeb className="scheduleList__item__day">
                          {t(
                            weekdayMap[schedule.day as keyof typeof weekdayMap]
                          )}
                        </CustomTypographyWeb>
                        <CustomTypographyWeb>
                          {t("{{start}} to {{end}}", {
                            start: this.translateTime(
                              schedule.start_time.replace(" ", "")
                            ),
                            end: this.translateTime(
                              schedule.end_time.replace(" ", "")
                            ),
                          })}
                        </CustomTypographyWeb>
                      </li>
                    );
                  })
                ) : (
                  <div className="scheduleList__specialDescription">
                    <img alt="" src={imgSchedule} width={162} />
                    <div>
                      <CustomTypographyWeb
                        variant="body1"
                        className="scheduleList__specialDescription__title"
                      >
                        {t("You have no regular schedule")}
                      </CustomTypographyWeb>
                      <CustomTypographyWeb variant="body1">
                        {t(
                          "You don't have any regular schedule set for your restaurants. Add a new regular schedule by clicking on the edit button."
                        )}
                      </CustomTypographyWeb>
                    </div>
                  </div>
                )}
              </ul>
            </div>
          </div>

          <div className="scheduleListContainer">
            <CustomTypographyWeb
              variant="h4"
              className="scheduleList__description"
            >
              {t("Special Schedule")}
            </CustomTypographyWeb>
            <CustomTypographyWeb
              variant="body1"
              className="scheduleList__description"
            >
              {t(
                "This is the schedule your restaurant follows when its regular operations are affected."
              )}
            </CustomTypographyWeb>

            {specialSchedules.length > 0 ? (
              <ul className="scheduleList">
                {specialSchedules.map((schedule) => {
                  return (
                    <li
                      className="scheduleList__item"
                      key={schedule.id}
                      data-schedule-type={schedule.schedule_type}
                    >
                      <CustomTypographyWeb className="scheduleList__item__day">
                        {t(weekdayMap[schedule.day as keyof typeof weekdayMap])}
                      </CustomTypographyWeb>
                      <CustomTypographyWeb>
                        {t("{{start}} to {{end}}", {
                          start: this.translateTime(
                            schedule.start_time.replace(" ", "")
                          ),
                          end: this.translateTime(
                            schedule.end_time.replace(" ", "")
                          ),
                        })}
                      </CustomTypographyWeb>
                    </li>
                  );
                })}
              </ul>
            ) : (
              <div className="scheduleList__specialDescription">
                <img alt="" src={imgSchedule} width={162} />
                <div>
                  <CustomTypographyWeb
                    variant="body1"
                    className="scheduleList__specialDescription__title"
                  >
                    {t("You have no special schedule set")}
                  </CustomTypographyWeb>
                  <CustomTypographyWeb variant="body1">
                    {t(
                      "You don't have any special schedule set for your restaurants. Add a new special schedule by clicking on the button below."
                    )}
                  </CustomTypographyWeb>
                </div>
                <CustomButton
                  color="primary"
                  variant="text"
                  className="scheduleList__add"
                  data-testid="btnAddSpecialSchedule"
                  onClick={this.showDrawer}
                >
                  {t("Add special Schedule")}
                </CustomButton>
              </div>
            )}
          </div>
        </Container>
      </>
    );
  }
}

const Container = styled("div")(({ theme }) => {
  return {
    display: "flex",
    gap: 10,

    "&.rtl": {
      direction: "rtl",
    },

    "& .scheduleListCOntainer": {
      padding: 12,
      flex: 1,
      border: "1px solid #EDEDED",
      borderRadius: 8,
    },
    "& .scheduleList__descriptionContainer": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
    },
    "& .scheduleList__description": {
      marginBlockStart: 8,
    },
    "& .scheduleList__edit, & .scheduleList__add": {
      display: "inline-flex",
      paddingInline: 0,
    },
    "& .scheduleList__edit": {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 16.44 / 14,
      fontWeight: 600,
      justifyContent: "flex-end",
    },
    "& ul.scheduleList": {
      listStyle: "none",
      padding: 0,
      display: "flex",
      flexDirection: "column",
      gap: 15,
      paddingBlockStart: 24,
      marginBlockStart: 10,
      marginBlockEnd: 0,

      "& li.scheduleList__item": {
        display: "flex",
        justifyContent: "space-between",

        "&[data-schedule-type='special'] > p": {
          color: "#F66",
        },

        "& > p": {
          fontWeight: 600,
          fontFamily: "Inter",
          lineHeight: 16.94 / 14,
        },

        "& .scheduleList__item__day": {
          textTransform: "capitalize",
        },
      },
    },
    "& .scheduleList__specialDescription": {
      paddingInline: 34,
      marginBlockStart: 20,
      textAlign: "center",
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      gap: 20,
      "& .scheduleList__specialDescription__title": {
        fontWeight: 600,
        marginBlockEnd: 5,
      },
    },
  };
});

export default withTranslation()(Servicespecificsettingsadmin2OpeningTimes);
// Customizable Area End
