// Customizable Area Start
import React from "react";
import {
  Box,
  Grid,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { ArrowUpward } from "@material-ui/icons";
import moment from "moment";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Text,
} from "recharts";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomPagination from "../../../components/src/CustomPagination";
import CustomRechartRevenueTooltip from "../../../components/src/CustomRechartRevenueTooltip.web";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomTable from "../../../components/src/CustomTable";
import CustomTypography from "../../../components/src/CustomTypography.web";
import KitchenDashboardStatisticsCard, {
  DashboardAdminStatistics,
} from "../../../components/src/KitchenDashboardStatisticsCard.web";
import AdminRestaurantStatusTag from "../../../components/src/AdminRestaurantStatusTag.web";
import DashboardAdminController, {
  Props,
} from "./DashboardAdminController.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import AdminDashboardMenu from "../../../components/src/AdminDashboardMenu.web";
import AdminDashboardDetailFormDialog from "../../../components/src/AdminDashboardDetailFormDialog.web";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { sortIncrease } from "./assets";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "& *": {
        boxSizing: "border-box",
      },

      "&.rtl": {
        direction: "rtl",
      },
    },

    statistics: {
      display: "flex",
      gap: 24,
      flexWrap: "wrap",
    },

    statisticsItem: {
      flex: 1,
    },

    heading: {
      fontFamily: "Roboto !important",
      fontSize: "14px !important",
      fontWeight: "600 !important" as any,
      lineHeight: "22.42px !important" as any,
    },

    filterSelect: {
      width: 100,
    },

    revenueAndSalesInner: {
      display: "flex",
      flexWrap: "wrap",
    },

    revenue: {
      padding: "24px 28px",
      border: "1px solid #EDEDED",
      borderRadius: 8,
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
    },

    revenueHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    revenueChart: {
      marginTop: "auto",
    },

    revenueChartLegend: {
      marginTop: 10,
      padding: 16,
    },

    revenueChartLegendLabel: {
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      lineHeight: "16.61px !important",
      display: "inline-flex !important",
      alignItems: "center",
      gap: 8,

      "&::before": {
        width: 8,
        height: 8,
        display: "inline-block",
        borderRadius: "50%",
        content: '" "',
        boxSizing: "border-box",
        backgroundColor: "#FF6666",
      },
    },

    salesDetail: {
      height: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      gap: 24,
      flexWrap: "wrap",
    },

    restaurant: {
      marginTop: 32,
    },

    restaurantTable: {
      [theme.breakpoints.down("lg")]: {
        whiteSpace: "nowrap",
      },

      "& thead": {
        backgroundColor: "#FFFBF4",
      },
    },

    contactInfo: {
      fontFamily: "Inter !important",
    },

    registrationDate: {
      fontFamily: "Inter !important",
    },

    pagination: {
      marginTop: 24,
      display: "flex",
      justifyContent: "end",
    },

    salesHead: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },

    salesDetailChart: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 24,
    },

    salesDetailChartLegend: {
      marginTop: 32,
      width: "100%",
    },

    salesDetailChartLegendItem: {
      display: "flex",
      alignItems: "center",
      gap: 8,

      "& + &": {
        marginTop: 12,
      },
    },

    salesDetailChartLegendItemDot: {
      width: 12,
      height: 12,
      borderRadius: "50%",
    },

    salesDetailChartLegendItemName: {
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      color: "#0F172A !important",
    },

    salesDetailChartLegendItemNumber: {
      marginLeft: 8,
      fontWeight: "700 !important" as any,
      fontFamily: "Inter !important",
      fontSize: "12px !important",
      color: "#0F172A !important",
    },

    revenueHightlight: {
      fontFamily: "Roboto !important",
      fontSize: "32px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "40.77px !important",
    },

    revenueHightlightUp: {
      display: "flex",
      alignItems: "center",
      marginTop: 4,
    },

    revenueHightlightNumber: {
      fontFamily: "Inter !important",
      fontSize: "14px !important",
      fontWeight: "700 !important" as any,
      lineHeight: "22.42px !important",
      marginLeft: 4,
    },

    revenueHightlightUpNumber: {
      color: "#059669 !important",
    },

    revenueHightlightDownNumber: {
      color: "#FF6666 !important",
    },

    revenueHightlightIcon: {
      width: 18,
      height: 18,
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      "& svg": {
        fontSize: 10,
      },
    },

    revenueHightlightUpIcon: {
      backgroundColor: "#D1FAE5",

      "& svg": {
        color: "#059669",
      },
    },

    revenueHightlightDownIcon: {
      backgroundColor: "#FDE2E2",

      "& svg": {
        color: "#FF6666",
      },
    },

    gridItem: {
      flexBasis: "20%",
      margin: theme.spacing(1),
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      height: "100px",
    },

    staticItem: {
      marginBottom: 25,
    },

    itemWithMaxWidth: {
      maxWidth: 196,
    },

    sortHeader: {
      display: "flex",
      alignItems: "center",
    },

    sortIcon: {
      marginRight: 10,
    },

    rotate180: {
      transform: "rotate(180deg)",
      transition: "transform 0.5s ease",
    },

    chartArea: {
      width: "80%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },

    leftArea: {
      width: "20%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
  });

// Customizable Area End

export class DashboardAdmin extends DashboardAdminController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes, t, i18n } = this.props;

    const {
      loading,
      statisticsData,
      revenueChartData,
      revenueFilter,
      restaurantData,
      pagination,
      isSortDesc,
      adminDashboardDetailDialog,
      totalRevenue,
      revenuePercentage,
    } = this.state;

    const rtl = i18n.language === "ar";

    const statisticData1: [key: string, value: DashboardAdminStatistics][] =
      Object.entries(statisticsData).slice(0, 5);

    const statisticData2: [key: string, value: DashboardAdminStatistics][] =
      Object.entries(statisticsData).slice(-2);

    const statisticsCardData1: DashboardAdminStatistics[] =
      this.mapStatisticsData(statisticData1);

    const statisticsCardData2: DashboardAdminStatistics[] =
      this.mapStatisticsData(statisticData2);

    const isFullWidth = statisticsCardData1.every((data) => data.display);

    const filterValue = this.props.navigation.selectedDashboardFilter;

    const cancellationData = statisticsData.totalCancellation;
    const activeAdmin = statisticsData.activeAdmins;

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Box>
          <Grid
            container
            spacing={3}
            className={clsx(classes.revenueAndSalesInner, { ["rtl"]: rtl })}
          >
            {statisticsCardData1.map((data, index) => (
              <Grid
                item
                key={index}
                style={{ display: data.display ? "block" : "none" }}
                sm
                className={clsx({
                  [classes.itemWithMaxWidth]: !isFullWidth,
                })}
              >
                <KitchenDashboardStatisticsCard statistics={data} />
              </Grid>
            ))}

            {filterValue === "totalCancellation" && (
              <Grid
                item
                className={classes.itemWithMaxWidth}
                key={cancellationData.name}
              >
                <KitchenDashboardStatisticsCard statistics={cancellationData} />
              </Grid>
            )}

            {filterValue === "activeAdmins" && (
              <Grid
                item
                className={classes.itemWithMaxWidth}
                key={activeAdmin.name}
              >
                <KitchenDashboardStatisticsCard statistics={activeAdmin} />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={3} style={{ marginTop: 10 }}>
            <Grid item className={classes.chartArea}>
              <Box className={classes.revenue}>
                <Box className={classes.revenueHead}>
                  <CustomTypography className={classes.heading}>
                    {t("Revenue")}
                  </CustomTypography>

                  <CustomSelect
                    dir={rtl ? "rtl" : ""}
                    className={classes.filterSelect}
                    data-test-id="status-select"
                    placeholder="Order Status"
                    variant="outlined"
                    value={revenueFilter}
                    onChange={(e: any) =>
                      this.handleChangeRevenueFilter(e.target.value)
                    }
                  >
                    {this.timeOptions.map((option) => (
                      <CustomMenuItem value={option.value} key={option.id}>
                        {t(option.name)}
                      </CustomMenuItem>
                    ))}
                  </CustomSelect>
                </Box>

                <CustomTypography className={classes.revenueHightlight}>
                  ${totalRevenue}
                </CustomTypography>

                <Box className={classes.revenueHightlightUp}>
                  <Box
                    className={clsx(
                      classes.revenueHightlightIcon,
                      revenuePercentage < 0
                        ? clsx(
                            classes.revenueHightlightDownIcon,
                            classes.rotate180
                          )
                        : classes.revenueHightlightUpIcon
                    )}
                  >
                    <ArrowUpward />
                  </Box>
                  <CustomTypography
                    className={clsx(classes.revenueHightlightNumber, {
                      [classes.revenueHightlightDownNumber]:
                        revenuePercentage < 0,
                      [classes.revenueHightlightUpNumber]:
                        revenuePercentage >= 0,
                    })}
                  >
                    {this.formatRevenuePercentage(revenuePercentage)}%
                  </CustomTypography>
                </Box>

                <Box className={classes.revenueChart} dir="ltr">
                  <ResponsiveContainer width="100%" height={148}>
                    <AreaChart data={revenueChartData}>
                      <CartesianGrid vertical={false} strokeDasharray={3} />

                      <XAxis
                        reversed={rtl}
                        dataKey="title"
                        fontFamily="Inter"
                        fontSize={12}
                        tickMargin={10}
                        fontWeight={400}
                        tick={({ x, y, payload }) => {
                          return (
                            <Text
                              x={x}
                              y={y}
                              dx={0}
                              dy={16}
                              fontFamily="Inter"
                              fontSize={11}
                              textAnchor="middle"
                            >
                              {t(payload.value)}
                            </Text>
                          );
                        }}
                        tickLine={false}
                        stroke="#CBD5E1"
                      />

                      <YAxis
                        orientation={rtl ? "right" : "left"}
                        dataKey="value"
                        type="number"
                        fontFamily="Inter"
                        fontSize={12}
                        fontWeight={400}
                        tickMargin={8}
                        axisLine={false}
                        tickLine={false}
                        tickFormatter={(value) =>
                          Intl.NumberFormat("en", {
                            notation: "compact",
                          }).format(value)
                        }
                        domain={[1250, "auto"]}
                      />
                      <Tooltip
                        data-testid="tooltip"
                        content={<CustomRechartRevenueTooltip />}
                        cursor={{ strokeDasharray: 4, stroke: "#A2A2A2" }}
                      />

                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#FF6666"
                        strokeWidth={3}
                        fill="#FFE3E3"
                        activeDot={{
                          r: 8,
                          stroke: "#FFF",
                          strokeWidth: 5,
                          fill: "#F66",
                        }}
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </Box>

                <Box className={classes.revenueChartLegend}>
                  <CustomTypography className={classes.revenueChartLegendLabel}>
                    {t("Revenue")}
                  </CustomTypography>
                </Box>
              </Box>
            </Grid>

            <Grid item className={classes.leftArea}>
              {filterValue === "all" &&
                statisticsCardData2.map((data, index) => (
                  <Box
                    className={clsx(classes.statisticsItem, {
                      [classes.staticItem]: index === 0,
                    })}
                    key={data.name}
                  >
                    <KitchenDashboardStatisticsCard statistics={data} />
                  </Box>
                ))}
            </Grid>
          </Grid>
        </Box>

        <Box className={classes.restaurant}>
          <TableContainer>
            <CustomTable
              data-test-id="table"
              className={classes.restaurantTable}
            >
              <TableHead>
                <TableRow>
                  <TableCell>{t("Restaurant Name")}</TableCell>
                  <TableCell>{t("Location")}</TableCell>
                  <TableCell>{t("Contact Info")}</TableCell>
                  <TableCell>{t("Registration Date")}</TableCell>
                  <TableCell>{t("Status")}</TableCell>
                  <TableCell>
                    <Box
                      className={classes.sortHeader}
                      onClick={this.handleSort}
                      data-test-id="sort-button"
                    >
                      <img
                        src={sortIncrease}
                        className={clsx(
                          classes.sortIcon,
                          isSortDesc ? "" : classes.rotate180
                        )}
                      />{" "}
                      {t("A-Z")}
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {restaurantData.length ? (
                  restaurantData.map((data) => (
                    <TableRow
                      key={data.id}
                      data-test-id={`table-row-${data.id}`}
                    >
                      <TableCell>{data.name}</TableCell>
                      <TableCell>{data.location}</TableCell>
                      <TableCell className={classes.contactInfo}>
                        {data.contactInfo}
                      </TableCell>
                      <TableCell className={classes.registrationDate}>
                        {moment(data.registrationDate).format("YYYY-MM-DD")}
                      </TableCell>
                      <TableCell>
                        <AdminRestaurantStatusTag status={data.status} />
                      </TableCell>
                      <TableCell>
                        <Box className={classes.action}>
                          <AdminDashboardMenu
                            data-test-id="menu"
                            onSelectAction={() =>
                              this.openAdminBranchDetailDialog(data)
                            }
                          />
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6}>
                      <Box style={{ textAlign: "center" }}>{t("No data")}</Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </CustomTable>
          </TableContainer>

          <Box className={classes.pagination}>
            <CustomPagination
              variant="text"
              count={pagination.totalPages}
              page={pagination.page}
              total={pagination.totalCount}
              limit={pagination.limit}
              onChange={(_, page) => this.changePage(page)}
              showOffset
            />
          </Box>
        </Box>

        <AdminDashboardDetailFormDialog
          data-test-id="admin-dashboard-detail-dialog"
          dialog={adminDashboardDetailDialog}
          onClose={this.closeAdminDashboardDetailDialog}
          editAdminBranch={this.closeAdminDashboardDetailDialog}
        />

        <CustomLoadingBackdrop open={loading} />
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(withTranslation()(DashboardAdmin));
// Customizable Area End
