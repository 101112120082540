import React from "react";
import {
  makeStyles,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import CustomTable from "../CustomTable";

type Props = {
  data: {
    id: string;
    hour: string;
    sale: number;
  }[];
};

function CustomAnalyticsAdminSalesPerHourTable({ data }: Props) {
  const { t, i18n } = useTranslation();
  const dir = i18n.language === "ar" ? "rtl" : "ltr";

  const classes = useStyles();

  return (
    <TableContainer>
      <CustomTable dir={dir} className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell>{t("Sales Per Hour")}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => {
            return (
              <TableRow key={item.id}>
                <TableCell style={{ fontFamily: "Inter" }}>
                  {item.hour}
                </TableCell>
                <TableCell style={{ fontFamily: "Inter", textAlign: "end" }}>
                  {item.sale}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </CustomTable>
    </TableContainer>
  );
}

const useStyles = makeStyles((theme) => {
  return {
    table: {
      "& tr th, & tr td": {
        padding: 12,
      },
    },
  };
});

export default CustomAnalyticsAdminSalesPerHourTable;
