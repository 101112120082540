import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Search, Add, AddCircleOutline, Close } from "@material-ui/icons";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFilter, {
  CustomFilterMenu,
} from "../../../components/src/CustomFilter.web";
import CustomInputField from "../../../components/src/CustomInputField.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import KitchenMenuCategoryAccordion, {
  KitchenMenuCategory,
} from "../../../components/src/KitchenMenuCategoryAccordion.web";
import clsx from "clsx";
import KitchenMenuItemFormDrawer, {
  KitchenMenuItemFormDrawerProps,
} from "../../../components/src/KitchenMenuItemFormDrawer.web";
import KitchenMenuCategoryFormDrawer, {
  KitchenMenuCategoryFormDrawerProps,
} from "../../../components/src/KitchenMenuCategoryFormDrawer.web";
import KitchenMenuManagementEmpty from "./KitchenMenuManagementEmpty.web";
import { WithTranslation, withTranslation } from "react-i18next";
import KitchenMenuAddFilterDialog, {
  KitchenMenuAddFilterDialogProps,
} from "../../../components/src/KitchenMenuAddFilterDialog.web";
import { v4 as uuidv4 } from "uuid";
import CustomFilterTag from "../../../components/src/CustomFilterTag.web";
import { KitchenMenuItemStatus } from "../../../components/src/KitchenMenuItemStatusMenu.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    actions: {
      display: "flex",
      alignItems: "center",
      gap: 15,

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },

    addFilterButton: {
      backgroundColor: "#FFDD99",
      borderRadius: 26,
      color: "#000000",
    },

    filterGroup: {
      display: "flex",
      alignItems: "center",
      gap: 15,
    },

    search: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    addButton: {
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        order: 1,
      },

      [theme.breakpoints.up("md")]: {
        marginLeft: "auto",
      },

      "&.rtl": {
        [theme.breakpoints.up("md")]: {
          marginLeft: "initial",
          marginRight: "auto",
        },
      },
    },

    category: {
      width: "100%",
      marginTop: 12,
    },

    ribbon: {
      width: "100%",
      height: "100%",
    },

    ribbonOuter: {
      position: "relative",
      margin: "0 20px",
      bottom: 14,

      "&::after": {
        content: "''",
        position: "absolute",
        top: 0,
        right: -11,
        width: 0,
        height: 0,
        borderBottom: "14px solid #333333",
        borderRight: "11px solid transparent",
      },
    },

    ribbonTitle: {
      fontSize: "44px !important",
      lineHeight: "53.25px !important",
      color: "#FFFFFF",
    },

    ribbonInner: {
      display: "flex",
      minWidth: 82,
      minHeight: 107,
      padding: 5,
      background: "#FF6666",
    },

    ribbonContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      background:
        "radial-gradient(732.81% 75.69% at 50.88% 75.69%, #FFD279 0%, #FF6666 100%)",
    },

    ribbonClipPath: {
      clipPath:
        " polygon( 0% 60.026%,0% 0%,99.671% 0%,99.671% 60.026%,99.671% 60.026%,99.646% 60.525%,99.572% 61.018%,99.45% 61.502%,99.28% 61.976%,99.064% 62.438%,98.804% 62.884%,98.5% 63.314%,98.153% 63.726%,97.765% 64.116%,97.336% 64.482%,55.474% 97.573%,55.474% 97.573%,54.486% 98.238%,53.404% 98.755%,52.254% 99.124%,51.056% 99.346%,49.836% 99.42%,48.615% 99.346%,47.418% 99.124%,46.267% 98.755%,45.186% 98.238%,44.197% 97.573%,2.335% 64.482%,2.335% 64.482%,1.907% 64.116%,1.518% 63.726%,1.172% 63.314%,0.867% 62.884%,0.607% 62.438%,0.391% 61.976%,0.222% 61.502%,0.099% 61.018%,0.025% 60.525%,0% 60.026% )",
    },

    menuStrength: {
      minHeight: 104,
      backgroundColor: "#FFFBF4",
      borderRadius: 8,
      marginTop: 24,
      display: "flex",

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },

    menuStrengthLevel: {
      position: "relative",
    },

    menuStrengthTitle: {
      fontSize: "22px !important",
      color: "#FF6666",
    },

    menuStrengthCurrentLevel: {
      marginTop: 6,
    },

    menuStrengthDescription: {
      marginTop: 10,
    },

    menuStrengthInner: {
      display: "flex",
      flexDirection: "column",
      padding: "8px 12px",
      width: "100%",
    },

    menuStrengthHeading: {
      display: "flex",
      alignItems: "center",
    },

    menuStrengthViewMore: {
      marginLeft: "auto",

      "&.rtl": {
        [theme.breakpoints.up("md")]: {
          marginLeft: "initial",
          marginRight: "auto",
        },
      },
    },

    categoryHeading: {
      display: "flex",
      alignItems: "center",
      gap: 15,
      flexWrap: "wrap",
    },

    categoryTitle: {
      fontSize: "22px !important",
      lineHeight: "25.83px !important",
    },

    categoryData: {
      marginTop: 15,
    },

    categoryAdd: {
      marginLeft: "auto",

      "&.rtl": {
        marginLeft: "initial",
        marginRight: "auto",
      },
    },

    hint: {
      marginLeft: 12,
      color: "#FF0000 !important",
      fontWeight: "600 !important" as any,

      "&.rtl": {
        marginLeft: "initial",
        marginRight: 12,
      },
    },

    categoryDataItem: {
      "& + &": {
        marginTop: 15,
      },
    },

    filterList: {
      display: "flex",
      alignItems: "center",
      gap: 10,
      flexWrap: "wrap",
    },
  });

export interface Props extends WithStyles, WithTranslation {
  filters: string[];
  filterMenuList: CustomFilterMenu[];
  menuCategories: KitchenMenuCategory[];
  viewMoreCategories: KitchenMenuCategory[];
  categoryDrawer: KitchenMenuCategoryFormDrawerProps;
  itemDrawer: KitchenMenuItemFormDrawerProps;
  addFilterDialog: KitchenMenuAddFilterDialogProps;
  search: string;
  preferences: string[];
  sortByPrice: string;
  searchInputRef: React.RefObject<HTMLInputElement>;
  viewMore: boolean;

  changeFilter(value: string): void;
  openCategoryDrawer(): void;
  closeCategoryDrawer(): void;
  openItemDrawer(): void;
  closeItemDrawer(): void;
  createCategory(values: {
    name: string;
    image: File;
    categories: string[];
  }): void;
  openEditCategoryDrawer(id: string): void;
  editCategory(values: {
    name: string;
    image: File;
    categories: string[];
  }): void;
  updateKitchenMenuCatalogueStatus(
    id: string,
    value: KitchenMenuItemStatus
  ): void;
  createItem(values: {
    image: string | File;
    nameEn: string;
    nameAr: string;
    category: string;
    descriptionEn: string;
    descriptionAr: string;
    price: number;
    variants: {
      id: string;
      name: string;
      price: number;
    }[];
  }): void;
  openAddFilterDialog(): void;
  closeAddFilterDialog(): void;
  addFilter(preferences: string[], sortByPrice: string): void;
  handleSearch(e: any): void;
  clearSearch(): void;
  removePreference(value: string): void;
  clearSortByPrice(): void;
  toggleViewMore(): void;
}

interface CategoryDataProps extends WithStyles, WithTranslation {
  categories: KitchenMenuCategory[];

  openEditCategoryDrawer(id: string): void;
  updateKitchenMenuCatalogueStatus(
    id: string,
    value: KitchenMenuItemStatus
  ): void;
}

function CategoryDataComponent(props: CategoryDataProps) {
  const {
    classes,
    categories,

    openEditCategoryDrawer,
    updateKitchenMenuCatalogueStatus,
  } = props;

  return (
    <Box className={classes.categoryData}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {categories.map(
            (category, index) =>
              (index + 1) % 2 !== 0 && (
                <Box className={classes.categoryDataItem} key={category.id}>
                  <KitchenMenuCategoryAccordion
                    data-test-id={`kitchen-menu-category-accordion-${category.id}`}
                    category={category}
                    openEditCategoryDrawer={openEditCategoryDrawer}
                    updateKitchenMenuCatalogueStatus={
                      updateKitchenMenuCatalogueStatus
                    }
                  />
                </Box>
              )
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          {categories.map(
            (category, index) =>
              (index + 1) % 2 === 0 && (
                <Box className={classes.categoryDataItem} key={category.id}>
                  <KitchenMenuCategoryAccordion
                    data-test-id={`kitchen-menu-category-accordion${category.id}`}
                    category={category}
                    openEditCategoryDrawer={openEditCategoryDrawer}
                    updateKitchenMenuCatalogueStatus={
                      updateKitchenMenuCatalogueStatus
                    }
                  />
                </Box>
              )
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

const CategoryData = withStyles(styles)(
  withTranslation()(CategoryDataComponent)
);

interface OverviewCategoryProps extends WithStyles, WithTranslation {
  categories: KitchenMenuCategory[];

  openEditCategoryDrawer(id: string): void;
  updateKitchenMenuCatalogueStatus(
    id: string,
    value: KitchenMenuItemStatus
  ): void;
  openItemDrawer(): void;
}

function OverviewCategoryComponent(props: OverviewCategoryProps) {
  const {
    classes,
    i18n,
    t,
    categories,

    openItemDrawer,
    openEditCategoryDrawer,
    updateKitchenMenuCatalogueStatus,
  } = props;

  const rtl = i18n.language === "ar";

  return (
    <Box className={classes.category}>
      <Box className={classes.categoryHeading}>
        <CustomTypography className={classes.categoryTitle} variant="h2">
          {t("All Categories ({{number}})", {
            number: categories.length,
          })}
        </CustomTypography>

        <CustomTypography
          data-test-id="add-new-category-btn"
          className={clsx(classes.categoryAdd, { rtl })}
          variant="button"
          onClick={openItemDrawer}
        >
          {t("Add New Item")}
        </CustomTypography>
      </Box>

      <CategoryData
        data-test-id="category-data"
        categories={categories}
        openEditCategoryDrawer={openEditCategoryDrawer}
        updateKitchenMenuCatalogueStatus={updateKitchenMenuCatalogueStatus}
      />
    </Box>
  );
}

const OverviewCategory = withStyles(styles)(
  withTranslation()(OverviewCategoryComponent)
);

interface ViewMoreItemProps extends WithStyles, WithTranslation {
  categories: KitchenMenuCategory[];

  openEditCategoryDrawer(id: string): void;
  updateKitchenMenuCatalogueStatus(
    id: string,
    value: KitchenMenuItemStatus
  ): void;
}

function ViewMoreItemComponent(props: ViewMoreItemProps) {
  const {
    classes,
    i18n,
    t,
    categories,

    openEditCategoryDrawer,
    updateKitchenMenuCatalogueStatus,
  } = props;

  const rtl = i18n.language === "ar";

  return (
    <Box className={classes.category}>
      <Box className={classes.categoryHeading}>
        <CustomTypography className={classes.categoryTitle} variant="h2">
          {t("Items ({{number}})", {
            number: categories.length,
          })}
        </CustomTypography>

        <CustomTypography className={clsx(classes.hint, { rtl })}>
          {t(
            "Here is list of items that needs your attention to go to the next level"
          )}
        </CustomTypography>
      </Box>

      <CategoryData
        data-test-id="category-data"
        categories={categories}
        openEditCategoryDrawer={openEditCategoryDrawer}
        updateKitchenMenuCatalogueStatus={updateKitchenMenuCatalogueStatus}
      />
    </Box>
  );
}

const ViewMoreItem = withStyles(styles)(
  withTranslation()(ViewMoreItemComponent)
);
// Customizable Area End

export class KitchenMenuManagementOverview extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const {
      classes,
      filters,
      filterMenuList,
      menuCategories,
      categoryDrawer,
      itemDrawer,
      addFilterDialog,
      t,
      i18n,
      search,
      preferences,
      sortByPrice,
      searchInputRef,
      viewMore,
      viewMoreCategories,

      openCategoryDrawer,
      closeCategoryDrawer,
      openItemDrawer,
      closeItemDrawer,
      changeFilter,
      createCategory,
      openEditCategoryDrawer,
      editCategory,
      updateKitchenMenuCatalogueStatus,
      createItem,
      closeAddFilterDialog,
      addFilter,
      openAddFilterDialog,
      clearSearch,
      handleSearch,
      removePreference,
      toggleViewMore,
      clearSortByPrice
    } = this.props;

    const rtl = i18n.language === "ar";

    const preferenceFilters = [
      {
        id: uuidv4(),
        name: t("VEG"),
        value: "veg",
      },

      {
        id: uuidv4(),
        name: t("NON-VEG"),
        value: "non_veg",
      },

      {
        id: uuidv4(),
        name: t("EGG"),
        value: "egg",
      },
    ];

    const priceVariantFilters = [
      {
        id: uuidv4(),
        name: t("Price: High to Low"),
        value: "desc",
      },

      {
        id: uuidv4(),
        name: t("Price: Low to High"),
        value: "asc",
      },
    ];

    return (
      <Box
        className={clsx(classes.container, {
          ["rtl"]: rtl,
        })}
      >
        <Box className={classes.actions}>
          <CustomInputField
            inputRef={searchInputRef}
            className={classes.search}
            variant="outlined"
            placeholder={t("Search")}
            InputProps={{
              endAdornment: (
                <>
                  {search && (
                    <IconButton
                      data-test-id="clear-search-btn"
                      size="small"
                      aria-label="toggle password visibility"
                      onClick={clearSearch}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  )}
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                </>
              ),
            }}
            onKeyDown={handleSearch}
          />

          <Box className={classes.filterGroup}>
            <CustomFilter
              label={t("Filter")}
              value={filters}
              onChange={changeFilter}
              menu={filterMenuList}
              multiple
              dir={rtl ? "rtl" : "ltr"}
            />

            <CustomButton
              data-test-id="add-filter-btn"
              dir={rtl ? "ltr" : ""}
              className={classes.addFilterButton}
              endIcon={<Add />}
              variant="contained"
              onClick={openAddFilterDialog}
            >
              {t("Add Filter")}
            </CustomButton>

            <Box className={classes.filterList}>
              {preferenceFilters.map(
                (filter) =>
                  preferences.includes(filter.value) && (
                    <CustomFilterTag
                      key={filter.id}
                      active
                      name={filter.name}
                      value={filter.value}
                      onSelect={() => removePreference(filter.value)}
                    />
                  )
              )}

              {priceVariantFilters.map(
                (filter) =>
                  sortByPrice === filter.value && (
                    <CustomFilterTag
                      key={filter.id}
                      active
                      name={filter.name}
                      value={filter.value}
                      onSelect={clearSortByPrice}
                    />
                  )
              )}
            </Box>
          </Box>

          <CustomButton
            data-test-id="add-new-btn"
            className={clsx(classes.addButton, { rtl })}
            startIcon={<AddCircleOutline />}
            variant="contained"
            color="primary"
            onClick={openCategoryDrawer}
          >
            {t("Add New")}
          </CustomButton>
        </Box>

        {menuCategories.length ? (
          <>
            <Box className={classes.menuStrength}>
              <Box className={classes.menuStrengthLevel}>
                <Box className={classes.ribbon}>
                  <Box className={classes.ribbonOuter}>
                    <Box
                      className={clsx(
                        classes.ribbonInner,
                        classes.ribbonClipPath
                      )}
                    >
                      <Box
                        className={clsx(
                          classes.ribbonContent,
                          classes.ribbonClipPath
                        )}
                      >
                        <CustomTypography
                          className={classes.ribbonTitle}
                          variant="subtitle1"
                        >
                          {menuCategories.length}
                        </CustomTypography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className={classes.menuStrengthInner}>
                <Box className={classes.menuStrengthHeading}>
                  <CustomTypography
                    className={classes.menuStrengthTitle}
                    variant="h2"
                  >
                    {t("Menu Strength")}
                  </CustomTypography>

                  {viewMore ? (
                    <CustomTypography
                      className={clsx(classes.menuStrengthViewMore, {
                        rtl,
                      })}
                      data-test-id="view-more-back-btn"
                      variant="button"
                      onClick={toggleViewMore}
                    >
                      {t("Back")}
                    </CustomTypography>
                  ) : (
                    <CustomTypography
                      data-test-id="view-more-btn"
                      className={clsx(classes.menuStrengthViewMore, {
                        rtl,
                      })}
                      variant="button"
                      onClick={toggleViewMore}
                    >
                      {t("View More")}
                    </CustomTypography>
                  )}
                </Box>

                <CustomTypography
                  className={classes.menuStrengthCurrentLevel}
                  variant="body1"
                >
                  {t("Your current Level: {{level}}", {
                    level: t("Trainee"),
                  })}
                </CustomTypography>

                <CustomTypography
                  variant="body1"
                  className={classes.menuStrengthDescription}
                >
                  <CustomTypography variant="button">
                    {t("Keep going!")}
                  </CustomTypography>{" "}
                  {t(
                    "There are still items that need you attention. Add photos and descriptions to them quickly to strengthen your menu."
                  )}
                </CustomTypography>
              </Box>
            </Box>

            {viewMore ? (
              <ViewMoreItem
                data-test-id="view-more-item"
                categories={viewMoreCategories}
                openEditCategoryDrawer={() => {}}
                updateKitchenMenuCatalogueStatus={() => {}}
              />
            ) : (
              <OverviewCategory
                data-test-id="overview-category"
                categories={menuCategories}
                openItemDrawer={openItemDrawer}
                openEditCategoryDrawer={openEditCategoryDrawer}
                updateKitchenMenuCatalogueStatus={
                  updateKitchenMenuCatalogueStatus
                }
              />
            )}
          </>
        ) : (
          <KitchenMenuManagementEmpty>
            <CustomTypography>
              {t("You haven't added any menu yet, add them by clicking")}
              <br />"{t("Add New")}”
            </CustomTypography>
          </KitchenMenuManagementEmpty>
        )}

        <KitchenMenuCategoryFormDrawer
          data-test-id="kitchen-menu-category-form-drawer"
          drawer={categoryDrawer}
          onClose={closeCategoryDrawer}
          createCategory={createCategory}
          editCategory={editCategory}
        />

        <KitchenMenuItemFormDrawer
          data-test-id="kitchen-menu-item-form-drawer"
          drawer={itemDrawer}
          createItem={createItem}
          onClose={closeItemDrawer}
        />

        <KitchenMenuAddFilterDialog
          sortByPrice={sortByPrice}
          preferences={preferences}
          data-test-id="kitchen-menu-add-filer-drawer"
          dialog={addFilterDialog}
          onClose={closeAddFilterDialog}
          addFilter={addFilter}
        />
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
export default withStyles(styles)(
  withTranslation()(KitchenMenuManagementOverview)
);
// Customizable Area End
