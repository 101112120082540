import {
  Box,
  CircularProgress,
  Theme,
  Tooltip,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import CustomButton from "../CustomButton.web";
import KitchenDrawer from "../KitchenDrawer.web";
import CustomTypographyWeb from "../CustomTypography.web";
import CustomFormCheckBox from "../../../components/src/CustomFormCheckBox.web";
import { InfoRounded } from "@material-ui/icons";
import { StringSchema, array, object, string } from "yup";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import CustomSelectTime from "../CustomSelectTime.web";
import { weekdayMap } from "../Utils.web";

interface Props extends WithStyles, WithTranslation {
  open: boolean;
  loading: boolean;
  initialDays: string[];
  initialStartTime: string | undefined;
  initialEndTime: string | undefined;

  onClose(): void;
  onSubmit(values: any): void;
}

interface FormInputTimeListProps extends WithTranslation {
  timeList: string[];

  onSelect: (time: string) => void;
}

const translateTime = (time: string, language: string) => {
  moment.locale(language);

  return moment(time, ["h:mm A", "HH:mm"]).format("hh:mm A");
};

function convertTime(time: string | undefined) {
  let result = "";

  if (!time) {
    return result;
  }

  const period = time.slice(-2);
  const hours = parseInt(time.slice(0, 2));

  switch (time) {
    case "12:00 AM":
      result = "00:00";
      break;
    case "12:00 PM":
      result = "12:00";
      break;
    case "12:30 AM":
      result = "00:30";
      break;
    case "12:30 PM":
      result = "12:30";
      break;
    default:
      result = `${period === "PM" ? hours + 12 : hours}:${time.slice(3, 5)}`;
  }

  return result;
}

export class CustomServicespecificsettingsadmin2OpeningTimesDrawer extends React.Component<Props> {
  formCheckboxes = new Map([
    ["sunday", false],
    ["monday", false],
    ["tuesday", false],
    ["wednesday", false],
    ["thursday", false],
    ["friday", false],
    ["saturday", false],
  ]);

  constructor(props: Props) {
    super(props);
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (
      JSON.stringify(prevProps.initialDays.sort()) !==
      JSON.stringify(this.props.initialDays.sort())
    ) {
      this.props.initialDays.forEach((day) =>
        this.formCheckboxes.set(day, true)
      );
    }
  }

  handleSubmit = (values: any) => {
    const formattedValues = {
      days: values.days.filter(Boolean),
      start_time: convertTime(values.start_time),
      end_time: convertTime(values.end_time),
    };

    this.props.onSubmit(formattedValues);
  };

  render(): React.ReactNode {
    const {
      classes,
      open,
      loading,
      initialDays,
      initialEndTime,
      initialStartTime,
      t,
      i18n,

      onClose,
    } = this.props;

    const rtl = i18n.language === "ar";

    const schema = object({
      days: array().of(string()).default([]),
      start_time: string().when("days", {
        is: (days) => days?.length > 0,
        then: (_schema: StringSchema) =>
          _schema.required(t("Start time is required")),
        otherwise: (_schema: StringSchema) => _schema.notRequired(),
      }),
      end_time: string().when("days", {
        is: (days) => days?.length > 0,
        then: (_schema: StringSchema) =>
          _schema.required(t("End time is required")),
        otherwise: (_schema: StringSchema) => _schema.notRequired(),
      }),
    });

    return (
      <KitchenDrawer
        dir={rtl ? "rtl" : ""}
        anchor={rtl ? "left" : "right"}
        open={open}
        title={t("Special Schedule")}
        onClose={onClose}
        bottom={
          <Box className={classes.formBottom}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-testId="cancel-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formAddSpecialSchedule"
            >
              {loading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                t("Apply")
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={{
              ...schema.default(),
              days: initialDays,
              start_time: initialStartTime,
              end_time: initialEndTime,
            }}
            onSubmit={this.handleSubmit}
            data-testid="formikAddSpecialSchedule"
            validationSchema={schema}
          >
            {(props) => {
              return (
                <Form
                  id="formAddSpecialSchedule"
                  data-testid="formAddSpecialSchedule"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <div className={classes.formInner}>
                    <p className="form__input__title">
                      {t("Select times")}
                      <LightTooltip
                        title={
                          <CustomTypographyWeb style={{ fontFamily: "Inter" }}>
                            {t(
                              "{{time12Hour}} is {{time24Hour}} in 24-hour format",
                              {
                                time12Hour: translateTime(
                                  "12:00 AM",
                                  i18n.language
                                ),
                                time24Hour: "00:00",
                              }
                            )}
                            <br />
                            {t(
                              "{{time12Hour}} is {{time24Hour}} in 24-hour format",
                              {
                                time12Hour: translateTime(
                                  "12:30 AM",
                                  i18n.language
                                ),
                                time24Hour: "00:30",
                              }
                            )}
                            <br />
                            {t(
                              "{{time12Hour}} is {{time24Hour}} in 24-hour format",
                              {
                                time12Hour: translateTime(
                                  "12:00 PM",
                                  i18n.language
                                ),
                                time24Hour: "12:00",
                              }
                            )}
                            <br />
                            {t(
                              "{{time12Hour}} is {{time24Hour}} in 24-hour format",
                              {
                                time12Hour: translateTime(
                                  "12:30 PM",
                                  i18n.language
                                ),
                                time24Hour: "12:30",
                              }
                            )}
                          </CustomTypographyWeb>
                        }
                      >
                        <InfoRounded fontSize="inherit" />
                      </LightTooltip>
                    </p>
                    <CustomSelectTime
                      labelFrom={
                        props.values.start_time
                          ? translateTime(
                              props.values.start_time,
                              i18n.language
                            )
                          : t("Open time")
                      }
                      labelTo={
                        props.values.end_time
                          ? translateTime(props.values.end_time, i18n.language)
                          : t("Close time")
                      }
                      formikKeyFrom={"start_time"}
                      formikKeyTo={"end_time"}
                    />
                    <div className="form__input__checkboxes">
                      <p className="form__input__title">
                        {t("Select day")}
                        <LightTooltip
                          title={
                            <CustomTypographyWeb>
                              {t(
                                "Selected day will become special schedule. If day isn't selected, it will become regular schedule."
                              )}
                            </CustomTypographyWeb>
                          }
                        >
                          <InfoRounded fontSize="inherit" />
                        </LightTooltip>
                      </p>

                      {props.touched.days && props.errors.days && (
                        <CustomTypographyWeb className="form__input__error">
                          {props.errors.days}
                        </CustomTypographyWeb>
                      )}
                      <div className="form__input__checkboxesList">
                        {Array.from(this.formCheckboxes.keys()).map((day) => {
                          return (
                            <CustomFormCheckBox
                              key={day}
                              value={day}
                              checked={props.values.days.includes(day)}
                              label={
                                <CustomTypographyWeb className="form__input__checkboxLabel">
                                  {t(
                                    weekdayMap[day as keyof typeof weekdayMap]
                                  )}
                                </CustomTypographyWeb>
                              }
                              onChange={(event, checked) => {
                                this.formCheckboxes.set(day, checked);
                                const updated = Array.from(
                                  this.formCheckboxes.keys()
                                ).filter((_key) =>
                                  this.formCheckboxes.get(_key)
                                );
                                props.setFieldValue("days", updated);
                              }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      marginBlock: -16,

      [theme.breakpoints.down("lg")]: {
        paddingBlockStart: "1.5rem",
      },

      "& .form__input__title": {
        fontFamily: "Raleway",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 18.78 / 16,
        fontWeight: 600,
        color: "#333",
        marginBlock: "18px 5px",
      },

      "& .form__input__checkboxLabel": {
        fontFamily: "Raleway",
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 24 / 16,
        color: "#0F172A",
        textTransform: "capitalize",
      },

      "& .form__input__checkboxesList": {
        display: "flex",
        flexDirection: "column",
        gap: 10,
      },

      "& .form__input__error": {
        marginBlockStart: 5,
        color: "#DC2626",
        fontSize: theme.typography.pxToRem(12),
        fontFamily: "Inter",
        lineHeight: 18 / 12,
      },
      "& .portal": {
        flex: 1,
        position: "relative",
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },
  });

export default withStyles(styles)(
  withTranslation()(CustomServicespecificsettingsadmin2OpeningTimesDrawer)
);

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#333",
    boxShadow: theme.shadows[1],
    fontFamily: "Raleway",
    padding: theme.spacing(1.5),
  },
}))(Tooltip);
