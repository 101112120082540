export const userProfile = require("../assets/user-profile.png");
export const deFlag = require("../assets/DE.png");
export const ukFlag = require("../assets/UK.png");
export const bgKitchenNav = require("../assets/bg_kitchen_nav.png");
export const bgKitchenNavReverse = require("../assets/bg_kitchen_nav_reverse.png");
export const logoOnly = require("../assets/logo_only.svg");

export const adv = require("../assets/ic_adv.svg");
export const branch = require("../assets/ic_branch.svg");
export const compensations = require("../assets/ic_compensations.svg");
export const dashboard = require("../assets/ic_dashboard.svg");
export const finance = require("../assets/ic_finance.svg");
export const menu = require("../assets/ic_menu.svg");
export const promo = require("../assets/ic_promo.svg");
export const rating = require("../assets/ic_rating.svg");
export const report = require("../assets/ic_report.svg");
export const time = require("../assets/ic_time.svg");
export const users = require("../assets/ic_user.svg");
export const orders = require("../assets/ic_order.svg");
export const logout = require("../assets/ic_logout.svg");
export const notification = require("../assets/ic_notification.svg");
export const notificationActive = require("../assets/ic_notification_active.svg");
export const pickupLogo = require("../assets/pickup_logo.svg");
export const orderHelp = require("../assets/ic_admin_order_help.svg");

export const adminDashboard = require("../assets/ic_admin_dashboard.svg");
export const adminOrders = require("../assets/ic_admin_orders.svg");
export const adminReview = require("../assets/ic_admin_review.svg");
export const adminReport = require("../assets/ic_admin_report.svg");
export const adminAdvert = require("../assets/ic_admin_advert.svg");
export const adminCompensations = require("../assets/ic_admin_compensations.svg");
export const adminUsers = require("../assets/ic_admin_users.svg");
export const adminNotifications = require("../assets/ic_admin_notifications.svg");
export const adminFinance = require("../assets/ic_admin_finance.svg");
export const adminBranch = require("../assets/ic_admin_branch.svg");
export const adminAvatar = require("../assets/admin_avatar.png");
