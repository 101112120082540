import {
  Box,
  CircularProgress,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core";
import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "./CustomButton.web";
import CustomFormInputField from "./CustomFormInputField.web";
import KitchenDrawer from "./KitchenDrawer.web";
import CustomReviewsContent from "./reviews/CustomReviewsContent";
import { WithTranslation, withTranslation } from "react-i18next";
import { TFunction } from "i18next";
import CustomTypography from "./CustomTypography.web";

export interface OrderHelp {
  id: number | string;
  title: string;
  content: string;
  date: string;

  kitchenReplies: {
    id: number;
    type: string;
    attributes: {
      id: string;
      content: string;
      created_at: string;
      update_at: string;
    }
  }[];
}

const validationSchema = {
  fn: (t: TFunction<"translate", undefined>) =>
    Yup.object({
      reply: Yup.string()
        .required(t("Please enter reply."))
        .transform((reply) => reply.trim()),
    }),
};

interface Props extends WithStyles<any>, WithTranslation {
  open: boolean;
  review: OrderHelp;
  loading: boolean;
  onClose(): void;
  onSubmit(values: { reply: string }): void;
  replyContent: string;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      paddingBlockStart: "2.5rem",

      [theme.breakpoints.down("lg")]: {
        paddingBlockStart: "1.5rem",
      },
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,

      "& > button": {
        flex: 1,
      },
    },

    label: {
      fontWeight: 700,
      marginBottom: 10,
    },

    reply: {
      fontWeight: 600,
      lineHeight: "22px !important",
    },
  });

export class KitchenOrderHelpFormDrawer extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, open, review, loading, onClose, onSubmit, t, i18n, replyContent } =
      this.props;

    const rtl = i18n.language === "ar";

    const dir = rtl ? "rtl" : "ltr";

    return (
      <KitchenDrawer
        open={open}
        title={t("Reply Rating")}
        onClose={onClose}
        dir={dir}
        anchor={rtl ? "left" : "right"}
        bottom={
          <Box className={classes.formBottom} dir={dir}>
            <CustomButton
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              color="primary"
              variant="contained"
              type="submit"
              form="formReplyReview"
              data-test-id="reply-btn"
            >
              {loading ? (
                <CircularProgress color="inherit" size={"1.5rem"} />
              ) : (
                t("Reply")
              )}
            </CustomButton>
          </Box>
        }
        body={
          <Formik
            initialValues={{ reply: replyContent }}
            validationSchema={validationSchema.fn(t)}
            onSubmit={(values, { resetForm }) => {
              onSubmit(values);
              resetForm();
            }}
            data-test-id="order-help-item-formik"
            enableReinitialize
          >
            {(props) => {
              return (
                <Form
                  id="formReplyReview"
                  data-test-id="reviews-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                  dir={dir}
                >
                  <Box className={classes.formInner}>
                    <CustomReviewsContent
                      title={review.title}
                      description={review.content}
                      rating={0}
                      isDisplayRating={false}
                    />

                    <Box>
                      <CustomTypography className={classes.label}>
                        {t("Kitchen Reply")}
                      </CustomTypography>

                      {review.kitchenReplies ? (
                        review.kitchenReplies.map(
                          (reply: any) => (
                            <CustomTypography
                              key={reply.id}
                              className={classes.reply}
                            >
                              {reply.attributes.content}
                            </CustomTypography>
                          )
                        )
                      ) : (
                        <CustomTypography>{t("No Data")}</CustomTypography>
                      )}
                    </Box>

                    <CustomFormInputField
                      fullWidth
                      name="reply"
                      multiline
                      maxRows={3}
                      minRows={3}
                      label={t("Reply to user")}
                      placeholder=""
                      variant="outlined"
                      error={props.touched.reply && !!props.errors.reply}
                      data-test-id="reply-input"
                      dir={dir}
                    />
                  </Box>
                </Form>
              );
            }}
          </Formik>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation()(KitchenOrderHelpFormDrawer)
);
