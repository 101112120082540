// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { CustomSnackbarType } from "../../../components/src/CustomSnackbar.web";
import { WithTranslation } from "react-i18next";

export enum TABS {
  SALES = 0,
  ORDERS = 1,
  CUSTOMERS = 2,
}

export const configJSON = require("./config");

export interface Props extends WithTranslation {}

interface S {
  loading: boolean;
  tabActive: TABS;
  tabs: { value: TABS; name: string }[];

  snackbar: {
    open: boolean;
    type?: CustomSnackbarType;
    message: string;
  };

  customerTabFilters: { label: string; value: string }[];
  customerTabFilterValue: string;
  isCompareWithPreviousPeriod: boolean;
}

interface SS {
  id: any;
}

export default class AnalyticsAdminController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.state = {
      loading: false,

      tabActive: TABS.SALES,

      tabs: [
        { value: TABS.SALES, name: "Sales" },
        { value: TABS.ORDERS, name: "Operations" },
        { value: TABS.CUSTOMERS, name: "Customers" },
      ],

      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },

      customerTabFilters: [
        { label: "Last 7 days", value: "last_7_days" },
        { label: "Last 30 days", value: "last_30_days" },
        { label: "Last 6 Months", value: "last_6_months" },
        { label: "Last Year", value: "last_year" },
      ],
      customerTabFilterValue: "last_7_days",
      isCompareWithPreviousPeriod: false,
    };

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  openSnackbar = (type: CustomSnackbarType, message: string) => {
    this.setState({
      snackbar: {
        open: true,
        type,
        message,
      },
    });
  };

  closeSnackbar = () => {
    this.setState({
      snackbar: {
        open: false,
        type: undefined,
        message: "",
      },
    });
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    runEngine.debugLog("apiRequestCallId", apiRequestCallId);
    runEngine.debugLog("responseJson", responseJson);
  }

  changeActiveTab = (tab: TABS) => {
    this.setState({ tabActive: tab });
  };

  getActiveTabName = () => {
    switch (this.state.tabActive) {
      case TABS.ORDERS:
        return "Operations";

      case TABS.CUSTOMERS:
        return "Customers";

      case TABS.SALES:
        return "Sales";
    }
  };

  handlSaleTabSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    this.setState({ isCompareWithPreviousPeriod: checked });
  };
}

// Customizable Area End
