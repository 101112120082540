import {
  Box,
  ListItemText,
  MenuItem,
  Theme,
  WithStyles,
  createStyles,
  withStyles,
  CircularProgress,
} from "@material-ui/core";
import { Form, Formik, FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import CustomButton from "./CustomButton.web";
import CustomFormInputField from "./CustomFormInputField.web";
import CustomDialog from "./CustomDialog.web";
import CustomFormSelectField from "./CustomFormSelectField.web";
import CustomFormDatePickerField from "./CustomFormDatePickerField.web";
import { WithTranslation, withTranslation } from "react-i18next";
import { AdminPromocodesAdvertisingStatus } from "./AdminPromocodesAdvertisingStatusTag.web";
import { AdmindvertisingEngagement } from "./AdminPromocodesAdvertisingEngagement.web";
import { v4 as uuidv4 } from "uuid";

export interface AdminPromocodesAdvertising {
  requestId: string;
  restaurantName: string;
  startDate: Date;
  endDate: Date;
  engagement: AdmindvertisingEngagement;
  revenue: number;
  status: AdminPromocodesAdvertisingStatus;
}

export interface AdminPromocodesAdvertisingDetailFormDialogProps {
  open: boolean;
  loading: boolean;
  form: AdminPromocodesAdvertising;
  mode: string;
}

export interface Props extends WithStyles, WithTranslation {
  dialog: AdminPromocodesAdvertisingDetailFormDialogProps;
  onClose(): void;
  editAdminAdvertising(values: any): void;
}

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginBottom: 0,
    },

    formInner: {
      display: "flex",
      flexDirection: "column",
    },

    formBottom: {
      display: "flex",
      alignItems: "center",
      gap: 16,
      width: "100%",
      justifyContent: "end",

      "& > button": {
        width: 120,
      },
    },

    requestId: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    dateSubmitted: {
      "& .MuiInputBase-input": {
        fontFamily: "Inter !important",
      },
    },

    formInput: {
      marginTop: 20,
    },

    action: {
      marginTop: 20,
      display: "flex",
      justifyContent: "end",

      "&.disabled": {
        opacity: 0.5,
        pointerEvents: "none",
      },
    },

    cancel: {
      color: "#FF6666",
      backgroundColor: "#FFEAEA",
    },
  });

export class AdminPromocodesAdvertisingDetailFormDialog extends React.Component<Props> {
  private formProps?: FormikProps<any> = undefined;

  constructor(props: Props) {
    super(props);
  }

  statusOptions = [
    {
      id: uuidv4(),
      name: "Active",
      value: AdminPromocodesAdvertisingStatus.Active,
    },

    {
      id: uuidv4(),
      name: "InActive",
      value: AdminPromocodesAdvertisingStatus.InActive,
    },
  ];

  render(): React.ReactNode {
    const {
      classes,
      dialog,
      t,
      i18n,

      onClose,
      editAdminAdvertising,
    } = this.props;

    const { open, form } = dialog;

    const rtl = i18n.language === "ar";

    const validationSchema = Yup.object({
      startDate: Yup.string().nullable().required(t("Please enter Start Date")),
      endDate: Yup.string()
        .nullable()
        .required(t("Please enter End Date"))
        .test(
          "is-greater",
          t("End Date must be greater than Start Date"),
          function (value) {
            const { startDate } = this.parent;
            return (
              !startDate || !value || new Date(startDate) <= new Date(value)
            );
          }
        ),
      status: Yup.string()
        .nullable()
        .required(t("Please select Request Status")),
    });

    const disabled = dialog.mode === "view";

    const title = disabled ? t("View Details") : t("Edit Details");

    return (
      <CustomDialog
        dir={rtl ? "rtl" : ""}
        width={600}
        open={open}
        title={title}
        onClose={onClose}
        content={
          <Formik
            initialValues={{
              requestId: form.requestId,
              restaurantName: form.restaurantName,
              startDate: form.startDate,
              endDate: form.endDate,
              engagement: form.engagement.views,
              revenue: form.revenue,
              status: form.status,
            }}
            validationSchema={validationSchema}
            onSubmit={editAdminAdvertising}
            data-test-id="admin-compensation-formik"
          >
            {(props) => {
              this.formProps = props;

              return (
                <Form
                  data-test-id="admin-compensation-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <Box className={classes.formInner}>
                    <CustomFormInputField
                      className={classes.requestId}
                      fullWidth
                      name="requestId"
                      label={t("Request ID")}
                      placeholder={t("Enter Request ID")}
                      variant="outlined"
                      error={
                        props.touched.requestId && !!props.errors.requestId
                      }
                      data-test-id="request-id-input"
                      disabled
                    />

                    <CustomFormInputField
                      fullWidth
                      name="restaurantName"
                      label={t("Restaurant Name")}
                      placeholder={t("Enter Restaurant Name")}
                      variant="outlined"
                      error={
                        props.touched.restaurantName &&
                        !!props.errors.restaurantName
                      }
                      data-test-id="restaurant-name-input"
                      disabled
                    />

                    <Box className={classes.formInput}>
                      <CustomFormDatePickerField
                        locale={i18n.language}
                        className={classes.dateSubmitted}
                        dateFormat="yyyy-MM-dd"
                        name="startDate"
                        label={t("Start Date")}
                        placeholder={t("Enter Start Date")}
                        variant="outlined"
                        error={
                          props.touched.startDate && !!props.errors.startDate
                        }
                        data-test-id="start-date-input"
                        required
                        fullWidth
                        value={props.values.startDate}
                        disabled={disabled}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormDatePickerField
                        locale={i18n.language}
                        className={classes.dateSubmitted}
                        dateFormat="yyyy-MM-dd"
                        name="endDate"
                        label={t("End Date")}
                        placeholder={t("Enter End Date")}
                        variant="outlined"
                        error={props.touched.endDate && !!props.errors.endDate}
                        data-test-id="end-date-input"
                        required
                        fullWidth
                        value={props.values.endDate}
                        disabled={disabled}
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormInputField
                        fullWidth
                        name="engagement"
                        label={t("Engagement")}
                        placeholder={t("Enter Engagement")}
                        variant="outlined"
                        data-test-id="engagement-input"
                        disabled
                        error={
                          props.touched.engagement && !!props.errors.engagement
                        }
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormInputField
                        fullWidth
                        name="revenue"
                        label={t("Revenue")}
                        placeholder={t("Enter Revenue")}
                        variant="outlined"
                        error={props.touched.revenue && !!props.errors.revenue}
                        data-test-id="revenue-input"
                        disabled
                      />
                    </Box>

                    <Box className={classes.formInput}>
                      <CustomFormSelectField
                        dir={rtl ? "rtl" : ""}
                        disabled={disabled}
                        fullWidth
                        name="status"
                        label={t("Request Status")}
                        variant="outlined"
                        error={props.touched.status && !!props.errors.status}
                        data-test-id="status-select"
                        required
                        value={props.values.status}
                        inputProps={{
                          renderValue: (selected: string) => {
                            if (!selected) {
                              return t("Active");
                            }

                            const selectedOption = this.statusOptions.find(
                              (option) => option.value === selected
                            );

                            if (!selectedOption) {
                              return "";
                            }

                            return t(selectedOption.name);
                          },
                        }}
                      >
                        {this.statusOptions.map((option) => (
                          <MenuItem
                            key={option.id}
                            value={option.value}
                            style={{
                              textAlign: rtl ? "right" : "left",
                            }}
                          >
                            <ListItemText primary={option.name} />
                          </MenuItem>
                        ))}
                      </CustomFormSelectField>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        }
        actions={
          <Box className={classes.formBottom}>
            <CustomButton
              className={classes.cancel}
              variant="contained"
              color="default"
              onClick={onClose}
              data-test-id="cancel-btn"
            >
              {t("Cancel")}
            </CustomButton>

            <CustomButton
              disabled={disabled}
              color="primary"
              variant="contained"
              data-test-id="submit"
              type="submit"
              onClick={() => this.formProps?.handleSubmit()}
            >
              {dialog.loading ? (
                <CircularProgress color="inherit" size={"1.5rem"} />
              ) : (
                t("Save")
              )}
            </CustomButton>
          </Box>
        }
      />
    );
  }
}

export default withStyles(styles)(
  withTranslation()(AdminPromocodesAdvertisingDetailFormDialog)
);
