// Customizable Area Start
import React from "react";
import { Box, Portal, Tab } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { SaveAlt } from "@material-ui/icons";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";
import CustomTabs from "../../../components/src/CustomTabs";
import CustomTypography from "../../../components/src/CustomTypography.web";
import AnalyticsAdminController, {
  Props,
  TABS,
} from "./AnalyticsAdminController.web";

import { withTranslation } from "react-i18next";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import { customLocaleMoment } from "../../../components/src/Utils.web";
import CustomAnalyticsAdminSalesRevenueChart from "../../../components/src/analytics/CustomAnalyticsAdminSalesRevenueChart.web";
import CustomAnalyticsAdminCustomersCompareChart from "../../../components/src/analytics/CustomAnalyticsAdminCustomersCompareChart.web";
import CustomAnalyticsAdminCustomersConversionChart from "../../../components/src/analytics/CustomAnalyticsAdminCustomersConversionChart.web";
import CustomAnalyticsAdminCustomersCard from "../../../components/src/analytics/CustomAnalyticsAdminCustomersCard.web";
import CustomAnalyticsAdminOrdersDelayAndPrepChart from "../../../components/src/analytics/CustomAnalyticsAdminOrdersDelayAndPrepChart.web";
import CustomAnalyticsAdminOrdersComplainsChart from "../../../components/src/analytics/CustomAnalyticsAdminOrdersComplainsChart.web";
import CustomAnalyticsAdminAvoidableCancellationChart from "../../../components/src/analytics/CustomAnalyticsAdminAvoidableCancellationChart.web";
import CustomAnalyticsAdminUnavailableTimeChart from "../../../components/src/analytics/CustomAnalyticsAdminUnavailableTimeChart.web";
import CustomAnalyticsAdminSalesMenuItemTable from "../../../components/src/analytics/CustomAnalyticsAdminSalesMenuItemTable.web";
import CustomAnalyticsAdminSalesPerHourTable from "../../../components/src/analytics/CustomAnalyticsAdminSalesPerHourTable.web";
import CustomSwitchWeb from "../../../components/src/CustomSwitch.web";
import CustomAnalyticsAdminSalesCompareChart from "../../../components/src/analytics/CustomAnalyticsAdminSalesCompareChart.web";

export class AnalyticsAdmin extends AnalyticsAdminController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      snackbar,
      loading,
      tabActive,
      tabs,
      customerTabFilters,
      customerTabFilterValue,
      isCompareWithPreviousPeriod,
    } = this.state;
    const { t, i18n } = this.props;

    const rtl = i18n.language === "ar";
    const dir = rtl ? "rtl" : "ltr";

    customLocaleMoment.locale(i18n.language);

    return (
      <Container dir={dir}>
        <CustomLoadingBackdrop open={loading} />

        <CustomTabs
          variant="scrollable"
          scrollButtons="auto"
          value={tabActive}
          dir={dir}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab
                key={index}
                data-testid={"tab-" + index}
                label={t(tab.name)}
                value={tab.value}
                onClick={() => this.changeActiveTab(tab.value)}
              />
            );
          })}
        </CustomTabs>
        <div className="reports__content">
          <div className="reports__header">
            <div>
              <CustomTypography className="reports__title">
                {t(this.getActiveTabName())}
              </CustomTypography>
              <CustomTypography className="reports__description">
                {t(
                  "Breakdown of total sales and order volume per day or per month. Use this to see whether your business is trending upwards or downwards over time."
                )}
              </CustomTypography>
            </div>

            <CustomButton
              variant="contained"
              color="primary"
              className="reports__download"
              startIcon={<SaveAlt />}
            >
              {t("Download Report")}
            </CustomButton>
          </div>
          {tabActive === TABS.SALES && (
            <Box
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gridColumnGap={8}
            >
              <span className="reporst__switchText">
                {t("Compare with Previous Period")}
              </span>
              <CustomSwitchWeb
                value={isCompareWithPreviousPeriod}
                onChange={this.handlSaleTabSwitchChange}
              />
            </Box>
          )}

          <div className="reports__main" dir={dir}>
            {tabActive === TABS.SALES && (
              <>
                <Box display={"flex"} gridColumnGap={30}>
                  <Box flex={"1 1 45%"} minWidth={0}>
                    <Box className="reports__card" sx={{ padding: 27 }}>
                      {isCompareWithPreviousPeriod ? (
                        <CustomAnalyticsAdminSalesCompareChart
                          total={3650}
                          data={MOCK_CHART_COMPARE}
                        />
                      ) : (
                        <>
                          <CustomTypography
                            className="reports__card__title"
                            dir={dir}
                          >
                            {t("Revenue Updates")}
                          </CustomTypography>

                          <Box
                            marginTop={"94px"}
                            maxWidth={500}
                            marginX={"auto"}
                          >
                            <CustomAnalyticsAdminSalesRevenueChart
                              data={MOCK_CHART_REVENUE}
                            />
                          </Box>
                        </>
                      )}
                    </Box>
                    <CustomAnalyticsAdminSalesPerHourTable
                      data={MOCK_SALES_PER_HOUR}
                    />
                  </Box>
                  <Box className="" flex={"1 1 55%"} minWidth={0}>
                    <CustomAnalyticsAdminSalesMenuItemTable
                      data={MOCK_MENU_ITEMS}
                    />
                  </Box>
                </Box>

                <Box mt={4.5}></Box>
              </>
            )}
            {tabActive === TABS.CUSTOMERS && (
              <>
                <Box>
                  <CustomTabs
                    value={customerTabFilterValue}
                    dir={dir}
                    classes={{ container: "reports__tabs" }}
                  >
                    {customerTabFilters.map((tab, index) => {
                      return (
                        <Tab
                          key={index}
                          data-testid={"tab-" + index}
                          className="reports__tab"
                          label={t(tab.label)}
                          value={tab.value}
                          onClick={() => {
                            this.setState({
                              customerTabFilterValue: tab.value,
                            });
                          }}
                        />
                      );
                    })}
                  </CustomTabs>
                </Box>
                <Box marginTop={"25px"} display={"flex"} gridColumnGap={"32px"}>
                  <Box className="reports__card">
                    <CustomAnalyticsAdminCustomersCompareChart
                      oldCustomers={362}
                      newCustomers={343}
                    />
                  </Box>
                  <Box flex={1}>
                    <Box className="reports__card">
                      <CustomAnalyticsAdminCustomersCard
                        actionButtonText={t("Book a promotion")}
                        actionText={t("Looking for new Customers?")}
                        headNumber="343"
                        headText={t("Orders From new Customers")}
                        headPercent="49"
                      />
                    </Box>

                    <Box className="reports__card" marginTop={"14px"}>
                      <CustomAnalyticsAdminCustomersCard
                        actionButtonText={t("Book a targeted Campaign")}
                        actionText={t("Looking for more returning Customers?")}
                        headNumber="362"
                        headText={t("Orders From Returning Customers")}
                        headPercent="51"
                      />
                    </Box>
                  </Box>
                </Box>
                <Box marginTop={"15px"} className="reports__card">
                  <CustomAnalyticsAdminCustomersConversionChart
                    menuView={[
                      {
                        value: 500,
                      },
                      { value: 100 },
                    ]}
                    addToCart={[
                      {
                        value: 200,
                      },
                      { value: 100 },
                    ]}
                    placeOrder={[
                      {
                        value: 100,
                      },
                      { value: 100 },
                    ]}
                  />
                </Box>
              </>
            )}
            {tabActive === TABS.ORDERS && (
              <>
                <Box>
                  <Box display={"flex"} gridColumnGap={28}>
                    <Box
                      className="reports__card"
                      flexBasis={"70%"}
                      minWidth={0}
                    >
                      <CustomAnalyticsAdminUnavailableTimeChart
                        data={MOCK_CHART_UNAVAILABLE_TIME}
                        unavailableTime={6}
                      />
                    </Box>
                    <Box
                      className="reports__card"
                      flexBasis={"30%"}
                      minWidth={0}
                    >
                      <CustomAnalyticsAdminAvoidableCancellationChart
                        lostSalesNumber={1619}
                        rejectionsNumber={10}
                        data={MOCK_CHART_AVOIDABLE_CANCELLATION}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} gridColumnGap={28} marginTop={"28px"}>
                    <Box
                      className="reports__card"
                      flexBasis={"40%"}
                      minWidth={0}
                    >
                      <Box maxWidth={"500px"} marginX={"auto"}>
                        <CustomAnalyticsAdminOrdersComplainsChart
                          data={MOCK_CHART_COMPLAINS}
                        />
                      </Box>
                    </Box>
                    <Box
                      className="reports__card"
                      flexBasis={"60%"}
                      minWidth={0}
                    >
                      <CustomAnalyticsAdminOrdersDelayAndPrepChart
                        targetNumber={5843}
                        ordersNumber={61}
                        data={MOCK_CHART_ORDERS_AND_DELAY}
                      />
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </div>
        </div>

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
            onClose={this.closeSnackbar}
          />
        </Portal>
      </Container>
    );
  }
}

const Container = styled("div")(({ theme }) => {
  return {
    "& .reporst__switchText": {
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 24 / 16,
    },
    "& .reports__content": {
      padding: "24px 24px 48px",
      borderRadius: 8,
      border: "1px solid #EDEDED",
      borderTopLeftRadius: 0,
      display: "flex",
      flexDirection: "column",
      gap: 10,
    },
    "& .reports__header": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "space-between",
      gap: 80,
    },
    "& .reports__title": {
      fontWeight: 600,
      fontSize: theme.typography.pxToRem(22),
      lineHeight: 26 / 22,
    },
    "& .reports__description": {
      marginBlockStart: 8,
    },
    "& .reports__download": {
      flexShrink: 0,
    },
    "& .reports__main": {
      marginBlockStart: 37,
    },
    "& .reports__card": {
      boxShadow: "0px 0px 4px 0px #00000040",
      borderRadius: 8,
      padding: "32px 44px 40px",
    },
    "& .reports__card__title": {
      fontFamily: "Inter",
      fontSize: theme.typography.pxToRem(20),
      fontWeight: 700,
      lineHeight: 24.2 / 20,
      textAlign: "center",
    },
    "& .reports__card__head": {
      display: "flex",
      gap: 8,
      "& span": {
        fontFamily: "Inter",
        fontSize: theme.typography.pxToRem(12),
        lineHeight: 14.52 / 12,
        "&.reports__card__headText": {
          color: "#767676",
          fontWeight: 700,
        },
        "&.reports__card__headNumber": {
          fontWeight: 700,
          color: "#404040",
        },
        "&.reports__card__headPercent": {
          color: "#94A3B8",
          fontWeight: 500,
        },
      },
    },
    "& .reports__card__action": {
      marginBlockStart: 22,
      borderRadius: 6,
      backgroundColor: "#F9F9F9",
      padding: 5,
      paddingInlineStart: 14,
      "& .reports__card__actionText": {
        display: "block",
        fontFamily: "Inter",
        fontSize: theme.typography.pxToRem(14),
        fontWeight: 500,
        lineHeight: 14 / 16.94,
        color: "#767676",
      },
      "& button": {
        fontFamily: "Inter",
        fontSize: theme.typography.pxToRem(10),
        lineHeight: 10 / 12.1,
        color: "#FF6666",
        marginBlockStart: 5,
      },
    },
  };
});

export default withTranslation()(AnalyticsAdmin);

const MOCK_MENU_ITEMS = [
  {
    id: "1",
    subtotal: 136.75,
    percentage: "18",
    menuItem: "Grilled Midyaf Feast Large",
  },
  {
    menuItem: "Grilled Midyaf Feast Large",
    id: "2",
    subtotal: 50.64,
    percentage: "18",
  },
  {
    percentage: "18",
    id: "3",
    subtotal: 136.75,
    menuItem: "Grilled Midyaf Feast Large",
  },
  {
    subtotal: 136.75,
    percentage: "18",
    id: "4",
    menuItem: "Grilled Midyaf Feast Large",
  },
  {
    id: "5",
    menuItem: "Grilled Midyaf Feast Large",
    subtotal: 136.75,
    percentage: "18",
  },
  {
    menuItem: "Grilled Midyaf Feast Large",
    subtotal: 136.75,
    id: "6",
    percentage: "18",
  },
  {
    percentage: "18",
    subtotal: 136.75,
    id: "7",
    menuItem: "Grilled Midyaf Feast Large",
  },
  {
    id: "8",
    percentage: "18",
    menuItem: "Grilled Midyaf Feast Large",
    subtotal: 136.75,
  },
];

const MOCK_CHART_REVENUE = [
  { name: "Monday", revenue: 3000 },
  { name: "Tuesday", revenue: 4000 },
  { name: "Wednesday", revenue: 2000 },
  { name: "Thursday", revenue: 5000 },
  { name: "Friday", revenue: 4000 },
  { name: "Saturday", revenue: 2000 },
  { name: "Sunday", revenue: 3000 },
];

const MOCK_CHART_COMPLAINS = [
  { name: "Monday", complainCount: 2 },
  { name: "Tuesday", complainCount: 0 },
  { name: "Wednesday", complainCount: 0 },
  { name: "Thursday", complainCount: 6 },
  { name: "Friday", complainCount: 0 },
  { name: "Saturday", complainCount: 0 },
  { name: "Sunday", complainCount: 2 },
];

const generateMockData = () => {
  const mockData = [];
  for (let i = 1; i <= 24; i++) {
    mockData.push({
      time: `${i}h`,
      value: i % 2 === 0 ? 6 : 9,
    });
  }
  return mockData;
};

const MOCK_CHART_UNAVAILABLE_TIME = generateMockData();

const MOCK_CHART_ORDERS_AND_DELAY = [
  { name: "Monday", revenue: 100 },
  { name: "Tuesday", revenue: 100 },
  { name: "Wednesday", revenue: 2000 },
  { name: "Thursday", revenue: 3000 },
  { name: "Friday", revenue: 4000 },
  { name: "Saturday", revenue: 4500 },
  { name: "Sunday", revenue: 4800 },
];

const MOCK_CHART_AVOIDABLE_CANCELLATION = [
  { name: "Monday", cancelation: 1 },
  { name: "Tuesday", cancelation: 2 },
  { name: "Wednesday", cancelation: 2 },
  { name: "Thursday", cancelation: 1 },
  { name: "Friday", cancelation: 1 },
  { name: "Saturday", cancelation: 2 },
];

const MOCK_SALES_PER_HOUR = [
  { id: "0", hour: "00:00", sale: 10 },
  { id: "1", hour: "01:00", sale: 10 },
  { id: "2", hour: "02:00", sale: 10 },
  { id: "3", hour: "03:00", sale: 10 },
];

const MOCK_CHART_COMPARE = [
  { period: 10, currentPeriod: 20, previousPeriod: 15 },
  { period: 11, currentPeriod: 38, previousPeriod: 28 },
  { period: 12, currentPeriod: 25, previousPeriod: 18 },
  { period: 13, currentPeriod: 30, previousPeriod: 21 },
  { period: 14, currentPeriod: 20, previousPeriod: 15 },
];
// Customizable Area End
