// Customizable Area Start
import React from "react";
import { Box, withStyles, createStyles } from "@material-ui/core";
import KitchenEmailAccountSignUpController, {
  Props,
} from "./KitchenEmailAccountSignUpController.web";
import AuthKitchenLayout from "../../../components/src/AuthKitchenLayout.web";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { kitchenLogo } from "./assets";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomFormPasswordField from "../../../components/src/CustomFormPasswordField.web";
import CustomFormInputField from "../../../components/src/CustomFormInputField.web";
import CustomButton from "../../../components/src/CustomButton.web";
import CustomFormCheckboxField from "../../../components/src/CustomFormCheckboxField.web";

const styles = createStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },

  logo: {
    width: 113,
    height: "auto",
  },

  heading: {
    marginTop: 32,
  },

  form: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    width: "100%",
    maxWidth: 362,
  },

  formInner: {
    marginTop: 16,
  },

  forgotPassword: {
    textAlign: "right",
    marginTop: 24,
  },

  submitBtn: {
    marginTop: 24,
  },

  registerSnackbar: {
    position: "absolute",
    left: 0,
    bottom: 0,
  },

  login: {
    marginTop: 20,
  },

  agree: {
    marginTop: 20,
  },

  agreeLabel: {
    paddingRight: 16,
  },

  link: {
    fontWeight: "600 !important" as any,
    textDecoration: "underline",
  },
});

const validationSchema = Yup.object({
  restaurantName: Yup.string()
    .nullable()
    .required("Please enter your restaurant name"),

  email: Yup.string()
    .nullable()
    .email("Invalid email address")
    .required("Please enter your email"),

  password: Yup.string()
    .nullable()
    .required("Please enter your password")
    .min(
      8,
      "Must include at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long."
    )
    .matches(
      /[A-Z]/,
      "Must include at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long."
    )
    .matches(
      /[a-z]/,
      "Must include at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long."
    )
    .matches(
      /\d+/,
      "Must include at least one lowercase letter, one uppercase letter, one digit, and be at least 8 characters long."
    ),

  agree: Yup.boolean()
    .oneOf([true], "Please agree with terms & conditions and privacy policy")
    .required("Please agree with terms & conditions and privacy policy"),
});
// Customizable Area End

export class KitchenEmailAccountSignUp extends KitchenEmailAccountSignUpController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { loading, registerSnackbar, registerSnackbarResponse } = this.state;
    const { classes } = this.props;

    return (
      <AuthKitchenLayout>
        <Box className={classes.container}>
          <Formik
            initialValues={{
              restaurantName: "",
              email: "",
              password: "",
              agree: false,
            }}
            validationSchema={validationSchema}
            onSubmit={this.register}
            data-test-id="register-formik"
          >
            {(props) => {
              this.updateSignUpFormProps(props);

              return (
                <Form
                  data-test-id="register-form"
                  className={classes.form}
                  onSubmit={props.handleSubmit}
                >
                  <img className={classes.logo} src={kitchenLogo} alt="logo" />

                  <CustomTypography
                    className={classes.heading}
                    color="primary"
                    variant="h2"
                  >
                    Register
                  </CustomTypography>

                  <Box className={classes.formInner}>
                    <CustomFormInputField
                      fullWidth
                      name="restaurantName"
                      label="Restaurant Name"
                      placeholder="Enter restaurant name"
                      variant="outlined"
                      error={
                        props.touched.restaurantName &&
                        !!props.errors.restaurantName
                      }
                      data-test-id="restaurant-name-input"
                      disabled={loading}
                    />

                    <CustomFormInputField
                      fullWidth
                      name="email"
                      label="Email"
                      placeholder="Enter email address"
                      variant="outlined"
                      error={props.touched.email && !!props.errors.email}
                      data-test-id="email-input"
                      disabled={loading}
                    />

                    <CustomFormPasswordField
                      fullWidth
                      name="password"
                      label="Password"
                      placeholder="Enter password"
                      variant="outlined"
                      error={props.touched.password && !!props.errors.password}
                      data-test-id="password-input"
                      disabled={loading}
                    />

                    <Box className={classes.agree}>
                      <CustomFormCheckboxField
                        data-test-id="agree-checkbox"
                        color="primary"
                        name="agree"
                        error={
                          props.touched.agree && !!props.errors.agree
                            ? props.errors.agree
                            : undefined
                        }
                        checked={props.values.agree}
                        onClick={this.handleOnClick}
                        onBlur={props.handleBlur}
                        disabled={loading}
                        checkboxLabel={
                          <CustomTypography
                            onClick={(e) => e.preventDefault()}
                            className={classes.agreeLabel}
                          >
                            I agree with{" "}
                            <CustomTypography
                              data-test-id="terms-and-conditions"
                              variant="button"
                              className={classes.link}
                              onClick={this.goTermsAndConditions}
                            >
                              terms & conditions
                            </CustomTypography>{" "}
                            and{" "}
                            <CustomTypography
                              data-test-id="privacy-policy"
                              className={classes.link}
                              variant="button"
                              onClick={this.goPrivacyPolicy}
                            >
                              privacy policy
                            </CustomTypography>
                          </CustomTypography>
                        }
                      />
                    </Box>
                  </Box>

                  <CustomButton
                    className={classes.submitBtn}
                    disabled={loading}
                    type="submit"
                    color="primary"
                    variant="contained"
                    data-test-id="register-btn"
                    fullWidth
                  >
                    Register Now
                  </CustomButton>

                  <CustomSnackbar
                    className={classes.registerSnackbar}
                    autoHideDuration={3000}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    open={registerSnackbar}
                    type={registerSnackbarResponse.type}
                    message={registerSnackbarResponse.message}
                    onClose={this.handleRegisterSnackbar}
                  />
                  <CustomTypography className={classes.login}>
                    if you are an already registered user?{" "}
                    <CustomTypography
                      data-test-id="back-login-btn"
                      variant="button"
                      onClick={this.login}
                    >
                      Login
                    </CustomTypography>
                  </CustomTypography>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </AuthKitchenLayout>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export default withStyles(styles)(KitchenEmailAccountSignUp);
// Customizable Area End
