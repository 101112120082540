import {
  Tabs,
  TabsProps,
  ThemeProvider,
  WithStyles,
  createStyles,
  createTheme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import clsx from "clsx";

const theme = createTheme({
  overrides: {
    MuiTabs: {
      root: {
        minHeight: "34px !important",

        "&.is-rounded": {
          display: "inline-flex",
          borderRadius: 50,
          border: "1px solid #EDEDED",
          height: "fit-content",

          "& button": {
            borderRadius: 50,
            border: "none",
            marginLeft: "0 !important",
          },
        },

        "&.reports__tabs": {
          backgroundColor: "#FF6666",
          padding: 2,
          maxWidth: "max-content",
          borderRadius: "50px",
        },
      },

      indicator: {
        display: "none",
      },
    },

    MuiTab: {
      root: {
        textTransform: "initial",
        fontFamily: "Raleway",
        fontSize: 14,
        fontWeight: 700,
        lineHeight: "22px",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        border: "1px solid #EDEDED",
        borderBottom: 0,
        minHeight: "34px !important",

        "& + &": {
          marginLeft: 5,
        },

        "&.Mui-selected": {
          backgroundColor: "#FF6666",
          color: "#FFFFFF",
          borderColor: "#FF6666",
        },
        "&.reports__tab": {
          fontWeight: 700,
          marginRight: 0,
          fontFamily: "Inter",
          textTransform: "none",
          border: "none",
          color: "#FFFFFF",
          opacity: 1,
          borderRadius: "50px",
          "&:hover": {
            color: "#FFFFFF",
          },
          "&$selected": {
            color: "#FF6666",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 8px 32px 0px #0000000F,0px 4px 8px 0px #00000008",
          },
          "&:focus": {
            color: "#FF6666",
            backgroundColor: "#FFFFFF",
          },
          "& + &": {
            marginLeft: 0,
          },
        },
      },
    },
  },
});

const styles = (theme: any) =>
  createStyles({
    container: {
      "& button": {
        [theme.breakpoints.up("sm")]: {
          minWidth: "89px !important",
        },
      },
    },
  });

export interface Props extends WithStyles<any> {
  rounded?: boolean;
}

export type CustomTabsProps = TabsProps & Props;

export class CustomTabs extends React.Component<CustomTabsProps> {
  constructor(props: CustomTabsProps) {
    super(props);
  }

  render(): React.ReactNode {
    const { classes, children, rounded, ...rest } = this.props;

    return (
      <ThemeProvider theme={theme}>
        <Tabs
          {...rest}
          className={clsx(classes.container, {
            "is-rounded": rounded,
          })}
        >
          {children}
        </Tabs>
      </ThemeProvider>
    );
  }
}

export default withStyles(styles)(CustomTabs);
