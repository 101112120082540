// Customizable Area Start
import React from "react";

import {
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  createStyles,
  withStyles,
  Portal,
  Theme,
} from "@material-ui/core";

import OrderHelpController, { Props } from "./OrderHelpController.web";

import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { iconCalendar } from "./assets";
import { customLocaleMoment } from "../../../components/src/Utils.web";

import CustomPagination from "../../../components/src/CustomPagination";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomSnackbar from "../../../components/src/CustomSnackbar.web";
import CustomTable from "../../../components/src/CustomTable";
import CustomMenuItem from "../../../components/src/CustomMenuItem.web";
import CustomDatePickerWeb from "../../../components/src/CustomDatePicker.web";
import CustomInputFieldWeb from "../../../components/src/CustomInputField.web";
import CustomTypography from "../../../components/src/CustomTypography.web";
import CustomSwitchWeb from "../../../components/src/CustomSwitch.web";
import CustomReviewsContent from "../../../components/src/reviews/CustomReviewsContent";
import CustomButton from "../../../components/src/CustomButton.web";
import KitchenOrderHelpFormDrawer from "../../../components/src/KitchenOrderHelpFormDrawer.web";
import CustomLoadingBackdrop from "../../../components/src/CustomLoadingBackdrop.web";

const styles = (theme: Theme) =>
  createStyles({
    container: {
      "&.rtl": {
        direction: "rtl",
      },
    },

    actions: {
      display: "flex",
      flexWrap: "wrap",
      gap: 10,
      justifyContent: "center",
      marginBottom: 100,

      [theme.breakpoints.down("xs")]: {
        flexDirection: "column-reverse",
      },
    },

    showAll: {
      display: "flex",
      alignItems: "center",
      gap: 8,
    },

    filterSelectRating: {
      "& .MuiOutlinedInput-root": {
        width: 180,
      },

      "& input::placeholder": {
        color: "#333333",
        opacity: 1,
      },
    },

    filterSelectDate: {
      maxWidth: 180,

      "& input::placeholder": {
        color: "#333333",
        opacity: 1,
      },
    },

    date: {
      whiteSpace: "pre",
      fontFamily: "Inter !important",
      fontWeight: 400,
      lineHeight: "22px !important",
    },

    pagination: {
      display: "flex",
      justifyContent: "end",
      marginTop: 24,
    },

    reviewCell: {
      width: "70%",
    },
  });

export class OrderHelp extends OrderHelpController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const { classes, t, i18n } = this.props;

    const rtl = i18n.language === "ar";

    const dir = rtl ? "rtl" : "ltr";

    const {
      ratingLevelFilter,
      loading,
      snackbar,
      pagination,
      dateFilter,
      listOrderHelp,
      isShowAll,
      orderHelpDrawer,
    } = this.state;

    return (
      <Box className={clsx(classes.container, { ["rtl"]: rtl })}>
        <Box className={classes.actions} dir={dir}>
          <Box className={classes.filterSelectDate}>
            <CustomDatePickerWeb
              data-test-id="select-date"
              onChange={this.changeDate}
              portalId="selectDate"
              selected={this.getSelectedDate(dateFilter)}
              dateFormat={"dd/MM/yyyy"}
              locale={i18n.language}
              customInput={
                <CustomInputFieldWeb
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    placeholder: t("Select Date"),
                    endAdornment: <img src={iconCalendar} />,
                    dir: dir,
                  }}
                />
              }
            />
          </Box>

          <Box className={classes.filterSelectRating}>
            <CustomSelect
              dir={dir}
              data-test-id="time-select"
              variant="outlined"
              value={ratingLevelFilter}
              placeholder={t("Rating level")}
            >
              <CustomMenuItem
                className={classes.languageMenuItem}
                value={"-1"}
                disabled
                dir={dir}
              >
                {t("Rating level")}
              </CustomMenuItem>

              {this.ratingLevelOptions.map((option) => (
                <CustomMenuItem value={option.value} key={option.id}>
                  {t(option.name)}
                </CustomMenuItem>
              ))}
            </CustomSelect>
          </Box>

          <Box className={classes.showAll}>
            <CustomSwitchWeb
              checked={isShowAll}
              dir={dir}
              onChange={this.handleSwitchChange}
              data-test-id="show-all"
            />

            <CustomTypography className={classes.switchLabel} dir={dir}>
              {t("Show All")}
            </CustomTypography>
          </Box>
        </Box>

        <TableContainer className={classes.tableContainer}>
          <CustomTable className={classes.table} dir={dir}>
            <TableHead>
              <TableRow>
                <TableCell>{t("Order Help")}</TableCell>
                <TableCell>{t("Date")}</TableCell>
                <TableCell>{t("Actions")}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {listOrderHelp.length ? (
                listOrderHelp.map((orderHelp, index) => (
                  <TableRow key={index} data-test-id={`table-row-${index}`}>
                    <TableCell className={classes.reviewCell}>
                      <CustomReviewsContent
                        title={orderHelp.title}
                        description={orderHelp.content}
                        rating={0}
                        isDisplayRating={false}
                      />
                    </TableCell>

                    <TableCell className={classes.date}>
                      {customLocaleMoment(orderHelp.date)
                        .locale(i18n.language)
                        .format("DD/MM/YYYY[\n]h:mma")}
                    </TableCell>

                    <TableCell>
                      <CustomButton
                        data-test-id="reply-user"
                        variant="text"
                        color="primary"
                        onClick={() => this.openDrawer(orderHelp)}
                      >
                        {t("Reply User")}
                      </CustomButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box style={{ textAlign: "center" }}>{t("No order help")}</Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </CustomTable>
        </TableContainer>

        <Box className={classes.pagination} dir={dir}>
          <CustomPagination
            data-test-id="pagination"
            variant="text"
            count={pagination.totalPages}
            page={pagination.page}
            total={pagination.totalCount}
            limit={pagination.limit}
            showOffset
          />
        </Box>

        <KitchenOrderHelpFormDrawer
          data-test-id="order-help-drawer"
          onClose={this.closeDrawer}
          onSubmit={this.replyUser}
          open={orderHelpDrawer.open}
          loading={orderHelpDrawer.loadingReply}
          review={orderHelpDrawer.review}
          replyContent={orderHelpDrawer.replyContent}
        />

        <Portal>
          <CustomSnackbar
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={snackbar.open}
            type={snackbar.type}
            message={snackbar.message}
          />
        </Portal>

        <CustomLoadingBackdrop open={loading} />
      </Box>
    );
  }
}

export default withStyles(styles)(withTranslation()(OrderHelp));
// Customizable Area End
